<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="catalogue-menu">
    <button
      :class="[
        'catalogue-dropdown-trigger',
        {
          active: uiStore.catalogueExpanded,
        },
      ]"
      data-catalogue-dropdown-trigger
      @click="toggle"
    >
      {{ content.title }}
    </button>

    <Transition :css="false" @enter="onDropdownEnter" @leave="onDropdownLeave">
      <div
        v-show="uiStore.catalogueExpanded"
        ref="dropdownElRef"
        class="dropdown"
      >
        <div class="dropdown-inner wrapper">
          <ul class="categories">
            <li
              v-for="(market, i) in content.children"
              :key="i"
              data-animate-in="true"
            >
              <button
                :id="`dropdown-market-trigger-${i}`"
                :class="[
                  'categories__item',
                  {
                    active: i === selectedCategoryIndex,
                  },
                ]"
                :aria-controls="`dropdown-market-${i}`"
                :aria-expanded="i === selectedCategoryIndex ? 'true' : 'false'"
                @click="selectMarket(i)"
              >
                <span>{{ market.title }}</span>
              </button>
            </li>
          </ul>

          <TransitionGroup
            tag="div"
            class="sub-categories"
            :css="false"
            @enter="onDropdownEnter"
            @leave="onDropdownLeave"
          >
            <div
              v-for="(market, i) in content.children"
              v-show="i === selectedCategoryIndex"
              :id="`dropdown-market-${i}`"
              :key="i"
              :aria-labelledby="`dropdown-market-trigger-${i}`"
              :aria-hidden="i !== selectedCategoryIndex ? 'true' : 'false'"
              class="sub-categories-inner"
              :class="{
                'is-child-free': !market.children[0].children,
              }"
            >
              <ul class="categories-lvl-2">
                <li
                  v-for="(subCategories, j) in market.children"
                  :key="j"
                  class="categories-lvl__item-wrapper"
                  :data-animate-in="
                    i === selectedCategoryIndex ? 'true' : 'false'
                  "
                >
                  <RouterLink
                    v-if="subCategories.children"
                    class="categories-lvl-2__item mb-s"
                    :to="subCategories.url"
                    @click="uiStore.catalogueExpanded = false"
                  >
                    <span v-html="subCategories.title"></span>
                    <SvgSprite symbol="ui-arrow-right" size="8" class="icon" />
                  </RouterLink>

                  <RouterLink
                    v-else
                    class="categories-lvl-3__item small-body-text"
                    :to="subCategories.url"
                    @click="uiStore.catalogueExpanded = false"
                  >
                    <span v-html="subCategories.title"></span>
                  </RouterLink>

                  <ul v-if="subCategories.children" class="categories-lvl-3">
                    <li
                      v-for="(subSubCategories, k) in subCategories.children"
                      :key="k"
                      :data-animate-in="
                        i === selectedCategoryIndex ? 'true' : 'false'
                      "
                    >
                      <RouterLink
                        class="categories-lvl-3__item small-body-text"
                        :to="subSubCategories.url"
                        @click="uiStore.catalogueExpanded = false"
                      >
                        <span v-html="subSubCategories.title"></span>
                      </RouterLink>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </TransitionGroup>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside, useFocusWithin } from '@vueuse/core'
import gsap from 'gsap'
import { nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'

import { useUiStore } from '@/stores/ui'

defineProps({
  content: {
    type: Object,
    required: true,
  },
})

const uiStore = useUiStore()
const selectedCategoryIndex = ref(0)
const selectedMarketElRef = ref<HTMLElement>()
const dropdownElRef = ref<HTMLElement>()

const toggle = () => {
  uiStore.catalogueExpanded = !uiStore.catalogueExpanded

  if (uiStore.catalogueExpanded) {
    uiStore.toggleScroll(false)
  } else {
    uiStore.toggleScroll(true)
  }
}

const selectMarket = (index: number) => {
  selectedCategoryIndex.value = index

  // Wait for the "market section" refresh then programmaticaly focus the first link found within
  nextTick(() => {
    if (selectedMarketElRef.value) {
      const firstLink = selectedMarketElRef.value.querySelector('a')
      if (firstLink) {
        firstLink.focus()
      }
    }
  })
}

// Watch focused element, if it is outside dropdownElRef, close dropdown
const setupCloseDropdownOnBlur = () => {
  const { focused } = useFocusWithin(dropdownElRef.value)
  watch(focused, focused => {
    // ℹ️ clicking the market trigger button will trigger this method because it is outside of dropdown.
    // Because this elements also update uiStore.catalogueExpanded value,
    // do not do anything in this case because is will create conflicts between this method and the onclick callback...
    const dropdownTriggerFocused = document.activeElement?.hasAttribute(
      'data-catalogue-dropdown-trigger'
    )

    if (!focused && !dropdownTriggerFocused) {
      uiStore.catalogueExpanded = false
    }
  })
}

// Close dropdown when pressing escape key
const onKeyUp = (e: KeyboardEvent) => {
  if (uiStore.catalogueExpanded && e.key === 'Escape') {
    uiStore.catalogueExpanded = false
    uiStore.toggleScroll(true)
  }
}

const onClickOustideDropdown = () => {
  if (uiStore.catalogueExpanded) {
    uiStore.catalogueExpanded = false
    uiStore.toggleScroll(true)
  }
}

onMounted(() => {
  setupCloseDropdownOnBlur()

  onClickOutside(dropdownElRef.value, onClickOustideDropdown, {
    ignore: ['[data-catalogue-dropdown-trigger]'],
  })

  document.addEventListener('keyup', onKeyUp)
})

onBeforeUnmount(() => {
  document.removeEventListener('keyup', onKeyUp)
})

// Shared between dropdown transition and markets grouptransition
// ℹ️ we're using data-animate-in="true" to avoid targeting elemnts not currently displayed
const onDropdownEnter = (el: Element, done: () => void) => {
  gsap.fromTo(
    el.querySelectorAll('[data-animate-in="true"]'),
    {
      y: 10,
      opacity: 0,
    },
    {
      y: 0,
      opacity: 1,
      stagger: 0.01,
      duration: 0.1,
      ease: 'Power4.inOut',
      onComplete: done,
    }
  )
}

// Shared between dropdown transition and markets grouptransition
const onDropdownLeave = (_el: Element, done: () => void) => {
  done()
}
</script>

<style lang="scss" scoped>
.catalogue-dropdown-trigger {
  @extend %button-nostyle;
  @extend %text-nodecoration;
  @extend %focusable;

  position: relative;
  display: inline-block;
  color: $c-white;
  font-size: 2rem;
  line-height: 3rem;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &::before {
    position: absolute;
    bottom: -0.2rem;
    left: 50%;
    width: 50%;
    height: 0.2rem;
    content: '';
    transform: translateX(-50%);
    background-color: $c-green-abr;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &.active,
  &:hover {
    color: $c-green-abr;

    &::before {
      width: 100%;
      opacity: 1;
    }
  }

  .is-light &,
  .header-primary:hover & {
    color: $c-black;
  }
}

.dropdown {
  position: fixed;
  z-index: 1; // Display above everyting to ensure there is no fase positive click outside
  top: calc($header-height - $spacing - 1px);
  right: 0;
  overflow: auto;
  width: 100%;
  max-height: calc(100vh - $header-height);
  padding: 3.6rem 0;
  background: $c-white;
  border-top: 0.1rem solid $c-grey-light;
}

.dropdown-inner {
  display: flex;
  flex-direction: column;
  gap: 3.8rem;

  @include mq(wrapper) {
    padding: 0 $spacing * 2;
  }
}

.categories {
  @extend %list-nostyle;

  display: flex;
  align-items: center;
  align-self: flex-start;
  margin: auto;
  padding: 0.2rem;
  background-color: rgba(0, 0, 0, 5%);
  border-radius: 6rem;
}

.categories__item {
  @extend %button-nostyle;
  @extend %focusable;
  @extend %fw-semi;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 27rem;
  padding: 0.9rem 3rem;
  color: $c-grey-medium;
  font-size: 1.6rem;
  font-style: normal;
  line-height: 2.2rem;
  text-align: center;
  cursor: pointer;
  background: rgba(10, 70, 53, 0%);
  border-radius: 6rem;
  transition: color 0.25s $ease-out-quart;

  > span {
    transition: all 0.25s $ease-out-quart;
  }

  &:hover {
    color: $c-black;
  }

  &.active {
    color: $c-white;
    background-color: $c-green-abr;
  }
}

.categories-lvl-2 {
  @extend %list-nostyle;

  // prettier-ignore
  @include fluid(gap, (l: 2.4rem, xxl: 4.8rem));

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  width: 100%;

  .is-child-free & {
    gap: 0 2.4rem;
  }

  @include mq(xl) {
    grid-template-columns: repeat(6, 1fr);
  }

  @include mq(wrapper) {
    .sub-categories-inner:not(.is-child-free) & {
      display: flex;
      justify-content: space-between;
    }
  }
}

.categories-lvl__item-wrapper {
  @include mq(wrapper) {
    .sub-categories-inner:not(.is-child-free) & {
      width: col(6);
    }
  }
}

.categories-lvl-2__item {
  @extend %fraunces-spec;
  @extend %fw-normal;

  display: flex;
  align-items: flex-end;
  height: $spacing * 2;
  padding-bottom: 0.6rem;
  color: $c-black;
  border-bottom: 1px solid $c-tag;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $c-green-abr;
    border-bottom: 1px solid $c-black;
  }

  span {
    font-size: 1.6rem;
    line-height: 2rem;
  }

  svg {
    flex: 0 0 1rem;
    margin-left: auto;
    transform: translateY(-0.5rem);
  }
}

.categories-lvl-3 {
  @extend %list-nostyle;

  line-height: 1.8rem;
}

.categories-lvl-3__item {
  display: block;
  padding-bottom: 0.9rem;
  color: $c-grey-dark;
  line-height: 1.8rem;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: $c-green-abr;
  }
}
</style>
