<!-- eslint-disable vue/no-v-html -->
<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <component :is="tag" ref="titleElRef" class="title">
    <span v-html="content"></span>
  </component>
</template>

<script setup lang="ts">
import gsap from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { onMounted, ref } from 'vue'

import { useAppear } from '@/utils/aware'
import { awaitPageTransition } from '@/utils/motion'

gsap.registerPlugin(SplitText)

const props = defineProps({
  content: {
    type: String,
    required: true,
  },
  tag: {
    type: String,
    default: 'div',
    required: false,
  },
  hasAnimation: {
    type: Boolean,
    default: true,
  },
})

const titleElRef = ref<HTMLElement | null>(null)
let splitTitle: SplitText

onMounted(() => {
  if (titleElRef.value && props.hasAnimation) {
    splitTitle = new SplitText(titleElRef.value, {
      type: 'words',
    })

    const tl = gsap.timeline()

    tl.set(splitTitle.words, {
      opacity: 0,
    })
  }
})

// FEATURE: appear
const isIn = ref(false)
const isFully = ref(false)
const pos = ref()

useAppear(
  titleElRef,
  async ({ isInViewport, isFullyInViewport, position }) => {
    if (isInViewport) {
      await awaitPageTransition()

      if (titleElRef.value && props.hasAnimation) {
        const tl = gsap.timeline()

        tl.to(
          splitTitle.words,
          {
            duration: 1,
            opacity: 1,
            stagger: 0.05,
            y: -10,
            ease: 'power4.out',
          },
          '<+0.2'
        )
      }
    }

    isIn.value = isInViewport
    isFully.value = isFullyInViewport
    pos.value = position
  },
  { once: true }
)
</script>

<style lang="scss" scoped>
.title {
  // :deep(> div) {
  //   position: relative;
  //   overflow: hidden;
  //   margin-bottom: -1.5rem;
  //   padding-bottom: 1.5rem;
  // }
}
</style>
