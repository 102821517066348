<template>
  <div v-if="showGrid" ref="root" class="grid wrapper--no-padding">
    <div class="grid-inner wrap">
      <div
        v-for="(col, index) in cols"
        :key="`grid-column-${index}`"
        class="grid__grid"
      >
        <div class="grid__grid__label">
          {{ index + 1 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'

import { gridCols as cols } from '@/config/app'

const root = ref<HTMLElement | null>(null)
const isVisible = ref(false)
const showGrid = computed(() => import.meta.env.MODE !== 'production')

const show = () => {
  root.value!.style.display = 'block'
  isVisible.value = true
}

const hide = () => {
  root.value!.style.display = 'none'
  isVisible.value = false
}

const toggle = () => {
  // eslint-disable-next-line no-unused-expressions
  isVisible.value ? hide() : show()
}

const onKeyDown = (e: KeyboardEvent) => {
  if (e.shiftKey && e.key === 'G') {
    toggle()
  }
}

onMounted(() => {
  if (!showGrid.value) {
    return
  }

  window.addEventListener('keydown', onKeyDown)
})
</script>

<style lang="scss" scoped>
[class*='grid--'],
.grid {
  @include get-all-space;

  position: fixed;
  z-index: 1000;
  display: none;
  opacity: 1;
  pointer-events: none;
}

.grid-inner {
  display: flex;
  height: 100%;
}

.grid__grid {
  position: relative;
  width: col(1, $grid-cols-number);

  &::before {
    @include get-all-space;

    content: '';
    border-left: 1px solid rgba($c-black, 0.1);
  }

  &:nth-child(3n + 1)::before {
    border-left: 1px solid rgba($c-black, 0.4);
  }

  &:last-child::before {
    border-right: 1px solid rgba($c-black, 0.8);
  }

  &:nth-child(6n + 1)::before {
    border-left: 1px solid rgba($c-black, 0.8);
  }
}

.grid__grid__label {
  @extend %fw-bold;
  @include center-x;

  bottom: 20px;
  color: $c-gray-medium;
  font-size: 1.1rem;
  line-height: 1em;
  white-space: nowrap;

  .grid__grid:nth-child(3n + 2) & {
    bottom: 40px;
  }

  @include mq(m) {
    font-size: 1.6rem;
  }
}
</style>
