import axios from 'axios'
import { ref } from 'vue'

import { endpointDefault, getApiUrl } from '@/config/app'
import { langDefault } from '@/config/languages'
import { segments } from '@/router/routes'
import { useResourceStore } from '@/stores/resource'
import { logger } from '@/utils/logger'

import type {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  AxiosResponseHeaders,
} from 'axios'
import type { Request } from 'express'
import type { InjectionKey, Ref } from 'vue'
import type { RouteLocationNormalized } from 'vue-router'
import type { Content, ResourceError, ResourceSingle } from '@/stores/resource'
import type { Language } from '@/types'

export const ContentKey: InjectionKey<Readonly<Ref<Content>>> =
  Symbol('Content')
export const contentDefault: Content = {
  title: 'Default title',
}
export const contentRef = ref<Content>(contentDefault)

// Manage HTML lang attribute…
const setHtmlLang = (res: ResourceSingle, lang: Language) => {
  const resource = typeof res === 'string' ? ({} as ResourceSingle) : res

  if (!resource.head) {
    resource.head = {}
  }

  if (resource.head.htmlAttrs) {
    resource.head.htmlAttrs.lang = lang
  } else {
    resource.head.htmlAttrs = { lang }
  }

  return resource
}

/**
 * Parse route to build an API request like ${api}/${endpoint}/${resource}.
 */
export const parseRoute = (to: RouteLocationNormalized) => {
  const { path, meta, name, params, query } = to
  const api = meta.api || getApiUrl()
  let endpoint = endpointDefault
  let resource = path

  // REVIEW: purpose???
  // const allowedParams = {
  //   news: ['categories', 'categories[]'],
  // }

  if (params.lang) {
    // Remove lang from path
    resource = resource.replace(new RegExp(`^/${params.lang}`), '')
  }

  if (meta.endpoint) {
    // Set API endpoint
    ;({ endpoint } = meta)
    // Remove endpoint from resource path
    // Check for a named route params or a registered segment
    /* eslint-disable indent */
    const pattern =
      params.endpoint ||
      (segments[endpoint]
        ? `(${endpoint}|${
            Array.isArray(segments[endpoint])
              ? (segments[endpoint] as string[]).join('|')
              : (segments[endpoint] as string)
          })`
        : endpoint)
    /* eslint-enable indent */
    resource = resource.replace(new RegExp(`^/${pattern}`), '')
  }

  // Pagination
  if (params.pageNumber) {
    // Remove /page/:page/ from resource
    resource = resource.replace(new RegExp(`/(page/${params.pageNumber}/)`), '')

    // Add as query param
    resource += `?page=${params.pageNumber}`
  }

  /**
   * Preview
   * URL should be like
   * - Draft (never published) :
   *    - page : /valid-route/?preview=true&page_id=666
   *    - post : /valid-route/?preview=true&p=post_type&id=666
   * - Already published (has a valid URL) :
   *    - all: /valid-route/?preview=true
   */
  if (query.preview === 'true') {
    // Manage preview
    const { page_id: pageId, id: postId, p: postType } = query

    if (pageId) {
      resource = `/${pageId}`
    }

    if (postType && postId) {
      endpoint = postType as string
      resource = `/${postId}`
    }
  }

  /**
   * Query string
   */
  // Will help us build the query string, put your params here
  const searchParams = new URLSearchParams()

  // Custom management a-la-mano
  // if (query['categories[]']) {
  //   const categories: string[] = Array.isArray(query['categories[]'])
  //     ? (query['categories[]'] as string[])
  //     : [query['categories[]']]
  //   categories.forEach(c => {
  //     searchParams.append('categories[]', c)
  //   })
  // }

  // Route meta auto management
  if (meta.keepQueryParams) {
    const keys = Object.keys(query)

    // Manage meta and qs values
    for (const key of keys) {
      // Clean and format value(s)
      const value = query[key]
      const valueArr = (Array.isArray(value) ? value : [value]).filter(
        v => v !== null
      ) as string[]

      // Use all or filter
      if (
        (meta.keepQueryParams as Boolean) === true ||
        (meta.keepQueryParams as string[]).includes(key)
      ) {
        if (key.endsWith('[]')) {
          // cat[]=1&cat[]=2
          valueArr.forEach(v => searchParams.append(key, v))
        } else {
          // cat=1,2
          searchParams.set(key, valueArr.join(','))
        }
      }
    }
  }

  // For Node<20, use `searchParams.toString() !== ''` instead
  if (searchParams.size > 0) {
    resource += `?${searchParams.toString()}`
  }

  if (name === '404') {
    resource = 'supercalifragilisticexpialidocious'
  }

  resource = resource.replace(/^\//, '')

  return {
    api,
    endpoint,
    resource,
  }
}

export async function fetchResource(
  to: RouteLocationNormalized,
  request?: Request
): Promise<
  [ResourceSingle | null, ResourceError | null, AxiosResponseHeaders]
> {
  const config: AxiosRequestConfig = {}
  const { meta, params, query } = to
  const lang = (params.lang as Language) || langDefault

  // No fetch for static routes
  if (meta.static) {
    return [
      {
        content: {},
        languages: {},
      } as ResourceSingle,
      null,
      {} as AxiosResponseHeaders,
    ]
  }

  const { api, endpoint, resource } = parseRoute(to)

  if (query && query.preview === 'true') {
    // Preview header
    config.headers = {
      'x-preview': 'true',
    }
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  if ((request?.headers as any)?.['X-Ecache-Disable']) {
    !config.headers && (config.headers = {})
    config.headers['X-Ecache-Disable'] = (request as any).headers[
      'X-Ecache-Disable'
    ] as string
  }
  /* eslint-enable @typescript-eslint/no-explicit-any */

  const url = `${api}/${endpoint}/${resource}`

  logger.info('[fetchResource]', url)

  try {
    const response = (await axios.get(
      url,
      config
    )) as AxiosResponse<ResourceSingle>

    // Nested, so what?
    // if (params.nested || meta.nested) {}
    const resource = setHtmlLang(response.data as ResourceSingle, lang)

    return [resource, null, response.headers as AxiosResponseHeaders]
  } catch (error) {
    const { isAxiosError } = error as AxiosError

    if (isAxiosError) {
      const { response } = error as AxiosError

      if (response && response.status === 404) {
        const resource = setHtmlLang(response.data as ResourceSingle, lang)

        return [
          resource,
          error as AxiosError,
          response.headers as AxiosResponseHeaders,
        ]
      }
    }

    logger.error(
      '[fetchResource]',
      (error as AxiosError).code || (error as Error).message
    )

    return [
      null,
      isAxiosError ? (error as AxiosError) : (error as Error),
      {} as AxiosResponseHeaders,
    ]
  }
}

/**
 * Fetch resource from API directly
 * without being related on routing.
 */
export async function fetchAPI(to: RouteLocationNormalized) {
  try {
    const { api, endpoint, resource } = parseRoute(to)
    const url = `${api}/${endpoint}/${resource}`

    logger.info('[fetchAPI]', url)

    const response = (await axios.get(url)) as AxiosResponse<ResourceSingle>

    return response.data as ResourceSingle
  } catch (error) {
    logger.error('[fetchAPI]', error)

    return null
  }
}

// TODO: types
// export const updateResource = <T extends ResourceSingle | ResourceArchive>({
export const updateResource = (resource: ResourceSingle) => {
  const { content, id, head, languages, slug, template, type, url } = resource

  contentRef.value = content as Content

  // Use a function rather than object (`$patch(object)`)
  // to make a "clean" reset of props (persistent previous properties)
  useResourceStore().$patch(state => {
    state.content = content
    state.id = id
    state.head = head
    state.languages = languages
    state.slug = slug
    state.template = template
    state.type = type
    state.url = url
  })
}
