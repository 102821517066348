<!-- eslint-disable vue/no-v-html -->
<template>
  <component
    :is="component"
    :class="useModifiers('action', modifiers)"
    :click="clickHandler"
    :enter="enterHandler"
    :leave="leaveHandler"
    :url="url"
    :to="to"
    :target="target"
    v-bind="$attrs"
  >
    <div v-if="title" class="action__label">
      <span v-html="title"></span>
      <span aria-hidden="true" v-html="title"></span>
    </div>

    <span v-if="icon.name" class="action__icon-wrapper">
      <SvgSprite
        v-for="index in 2"
        :key="`action__icon-${index}`"
        class="action__icon icon"
        :symbol="icon.name"
        :size="icon.viewbox"
        role="img"
        aria-label="enable"
      />
    </span>
    <slot />
  </component>
</template>

<script lang="tsx">
/* eslint-disable vue/one-component-per-file */

import { defineComponent, h } from 'vue'

import { iconRegistered, iconViewbox } from '@/config/app'
import { useModifiers } from '@/utils/filters'

import type { PropType } from 'vue'
import type { Icon } from '@/types'

type ActionTarget = '_blank'
type ActionTag = 'a' | 'button'
type ActionModifiers = string[] // 'arrow' | 'reverse' | 'small' | 'large' | …

interface ActionContent {
  title: string
  url?: string
  target?: ActionTarget
  tag?: ActionTag
  modifiers?: ActionModifiers
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  to?: null | Record<string, any>
  icon?: string | Icon
  iconsup: string
}

// Render functions
const props = {
  url: { type: String },
  to: { type: Object },
  target: { type: String },
  click: {
    type: Function as PropType<(e: MouseEvent) => void>,
    default: null,
  },
  enter: {
    type: Function as PropType<(e: MouseEvent) => void>,
    default: null,
  },
  leave: {
    type: Function as PropType<(e: MouseEvent) => void>,
    default: null,
  },
}

const ActionAnchor = defineComponent({
  name: 'ActionAnchor',
  props,
  setup: (props, ctx) => () =>
    h(
      <a href={props.url} onMouseenter={props.enter} onMouseleave={props.leave}>
        {ctx.slots.default?.()}
      </a>
    ),
})

const ActionOutside = defineComponent({
  name: 'ActionOutside',
  props,
  setup: (props, ctx) => () =>
    h(
      <a
        href={props.url}
        target={props.target}
        onMouseenter={props.enter}
        onMouseleave={props.leave}
        rel="noopener noreferrer"
      >
        {ctx.slots.default?.()}
      </a>
    ),
})

const ActionRouterLink = defineComponent({
  name: 'ActionRouterLink',
  props,
  setup: (props, ctx) => () =>
    h(
      <router-link
        to={props.url || props.to}
        onMouseenter={props.enter}
        onMouseleave={props.leave}
      >
        {ctx.slots.default?.()}
      </router-link>
    ),
})

const ActionButton = defineComponent({
  name: 'ActionButton',
  props,
  setup: (props, ctx) => () =>
    h(
      <button
        type="button"
        onClick={props.click}
        onMouseenter={props.enter}
        onMouseleave={props.leave}
      >
        {ctx.slots.default?.()}
      </button>
    ),
})

export default defineComponent({
  name: 'Action',
  props: {
    content: {
      type: Object as () => ActionContent,
      required: true,
      validator: (value: ActionContent) => {
        if (!value.to && (!value.tag || value.tag === 'a')) {
          !value.url &&
            console.warn('[GAction] url property is mandatory for links')

          return value.url !== undefined
        }

        return true
      },
    },
  },
  emits: ['on-click', 'on-enter', 'on-leave'],
  setup(props, ctx) {
    const clickHandler = (e: MouseEvent) => ctx.emit('on-click', e)
    const enterHandler = (e: MouseEvent) => ctx.emit('on-enter', e)
    const leaveHandler = (e: MouseEvent) => ctx.emit('on-leave', e)
    // REVIEW: is reactivity needed?
    // eslint-disable-next-line vue/no-setup-props-destructure
    const {
      title,
      url = '',
      to = null,
      tag,
      target = '',
      icon: iconData,
      modifiers = [],
    } = props.content
    const isButton = tag === 'button'
    const isAnchor = to === null && url && /^(http|#)/.test(url)
    const isOutside = target === '_blank'

    if (!isButton) {
      modifiers.push('link')
    }

    let component = ActionRouterLink
    if (isButton) {
      modifiers.push('btn')
      component = ActionButton
    } else if (isOutside) {
      component = ActionOutside
    } else if (isAnchor) {
      component = ActionAnchor
    }

    const icon: Icon = {} as Icon
    if (iconData) {
      modifiers.push('icon')

      if (typeof iconData === 'string' && iconRegistered[iconData]) {
        modifiers.push(iconData as string)
        icon.name = iconRegistered[iconData].symbol || ''
        icon.viewbox = iconRegistered[iconData].viewbox || iconViewbox
      } else {
        icon.name =
          (iconData as Icon).name ||
          iconRegistered[(iconData as Icon).slug || 'arrow']?.symbol
        icon.viewbox = (iconData as Icon).viewbox || iconViewbox
        modifiers.push(icon.name)
      }
    }

    return {
      useModifiers,
      clickHandler,
      enterHandler,
      leaveHandler,
      component,
      title,
      url,
      to: to || {},
      target,
      icon,
      modifiers,
    }
  },
})
</script>

<style lang="scss" scoped>
// Defaults
.action,
[class*='action--'] {
  --padding-h: 3rem;
  --padding-v: 0.9rem;
  --icon-size: 2.4rem;
  --icon-margin: 1rem;
  --hover-color: var(--c-action-bg-hover);

  @extend %fw-medium;
  @extend %button-nostyle;

  position: relative;
  display: inline-flex;
  overflow: hidden;
  padding: var(--padding-v) var(--padding-h);
  cursor: pointer;
  color: var(--c-action-text);
  font-size: 1.6rem;
  line-height: 2rem;
  background: var(--c-action-bg);
  border-radius: 4.4rem;
  transition: opacity 0.2s $ease-out;

  .action__label span {
    display: block;
    margin-top: 0.2rem;
    transition: all 0.2s ease-out;
    pointer-events: none;
  }

  .action__label span:nth-child(2),
  .action__icon:nth-child(2) {
    @include get-all-space;

    // width: fit-content;
    // text-align: center;
    opacity: 0;
    transform: translateY(130%);
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled):hover,
  &:not(:disabled):focus-visible {
    .action__label span:nth-child(1),
    .action__icon:nth-child(1) {
      opacity: 0;
      /* stylelint-disable-next-line declaration-no-important */
      transform: translateY(-130%) !important;
    }

    .action__label span:nth-child(2),
    .action__icon:nth-child(2) {
      opacity: 1;
      /* stylelint-disable-next-line declaration-no-important */
      transform: translateY(0%) !important;
    }
  }
}

// Links
[class*='action--'][class*='--link'] {
  color: $c-white;
  text-decoration: none;

  &[class*='--negative'] {
    color: $c-black;
  }
}

// Action has an icon before the label
[class*='action--'][class*='--reversed'] {
  flex-direction: row-reverse;
}

// Buttons (tag OR .btn)
// button.action,
// button[class*='action--'],
[class*='action--'][class*='--btn'] {
  &:disabled {
    opacity: 0.5;
  }
}

// Action with no background
[class*='action--'][class*='--transparent'] {
  --c-action-bg: transparent;
  --hover-color: transparent;
}

// Action with white background
[class*='action--'][class*='--white'] {
  --c-action-bg: $c-white;
  --hover-color: $c-white;

  color: $c-green-abr;
  font-weight: 600;
  border: 1px solid $c-green-abr;
}

/* stylelint-disable-next-line no-descending-specificity */
.action__label {
  position: relative;
  z-index: 1;
  display: inline-flex;

  &::first-letter {
    text-transform: uppercase;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  [class*='action--'][class*='--no-label'] & {
    @extend %visually-hidden;
  }
}

.action__icon-wrapper {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  margin-left: var(--icon-margin);
  transform: translateY(-0.1rem);
  transition: all 0.3s $ease-in-out;

  [class*='action--'][class*='--reversed'] & {
    margin-right: var(--icon-margin);
    margin-left: 0;
  }

  [class*='action--'][class*='--arrow'] & {
    transition: transform 0.3s $ease-out;
    transform: translateX(-0.5rem);
  }

  [class*='action--'][class*='--arrow-left'] & {
    transform: translateX(0.5rem);
  }

  [class*='action--'][class*='--no-label'] & {
    transform: none;
    margin-left: 0;
  }

  [class*='action--'][class*='--arrow']:not(:disabled):hover &,
  [class*='action--'][class*='--arrow']:not(:disabled):focus-visible & {
    transform: translateX(0);
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.action__icon {
  width: var(--icon-size);
  height: var(--icon-size);
  transition: all 0.2s ease-out;

  /* stylelint-disable-next-line no-descending-specificity */
  [class*='action--'][class*='--calendar'] & {
    stroke: $c-white;
    fill: transparent;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  [class*='action--'][class*='--download'] & {
    fill: $c-green-abr;
  }
}
</style>
