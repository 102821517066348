export function useModifiers(className: string, modifiers: string[]) {
  const mods = (modifiers || []).filter(modifier => modifier)

  return mods.length > 0 ? `${className}--${mods.join('--')}` : undefined
}

export const parseSets = (sets: Record<string, string>) =>
  Object.keys(sets || {}).reduce(
    (prev, current) =>
      // eslint-disable-next-line prefer-template
      `${prev ? prev + ' ' : ''}${sets[current]} ${current}w,`,
    ''
  )
