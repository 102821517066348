/* eslint-disable lines-around-comment */
import { useIntersectionObserver } from '@vueuse/core'

import type {
  MaybeElementRef,
  UseIntersectionObserverOptions,
  UseIntersectionObserverReturn,
} from '@vueuse/core'

export type AppearCallback = (data: {
  isInViewport: boolean
  isFullyInViewport: boolean
  position: AppearPosition
}) => void
export type AppearPosition = 'top' | 'bottom' | 'center' | undefined
export interface AppearOptions extends UseIntersectionObserverOptions {
  /**
   * Execute the callback only once.
   *
   * @default false
   */
  once?: boolean
}

// TODO: question - centralized/shared observer?

/**
 * Detects that a target element's visibility.
 *
 * @see https://vueuse.org/useIntersectionObserver
 * @param target
 * @param callback
 * @param options
 */
export function useAppear(
  target: MaybeElementRef,
  callback: AppearCallback,
  options: AppearOptions = {
    root: null,
    rootMargin: '0px 0px 0px 0px',
    threshold: [0, 1],
    once: false,
  }
): UseIntersectionObserverReturn {
  function wrappedCallback(
    entries: IntersectionObserverEntry[],
    observer: IntersectionObserver
  ) {
    entries.forEach(entry => {
      // Each entry element corresponds to a set of properties
      // for one of the target elements:
      const {
        boundingClientRect,
        intersectionRatio,
        // intersectionRect,
        isIntersecting,
        rootBounds,
        // target,
        // time,
      } = entry

      let isInViewport = false
      let isFullyInViewport = false

      if (isIntersecting) {
        isInViewport = true

        if (intersectionRatio >= 1) {
          isFullyInViewport = true
        } else {
          isFullyInViewport = false
        }

        let position: AppearPosition | undefined = undefined

        if (rootBounds) {
          const isBeforeViewport = boundingClientRect.top < rootBounds.top
          const isAfterViewport =
            boundingClientRect.top + boundingClientRect.height >
            rootBounds.top + rootBounds.height

          position = 'center'

          if (isBeforeViewport) {
            position = 'top'
          } else if (isAfterViewport) {
            position = 'bottom'
          }
        }

        callback({ isInViewport, isFullyInViewport, position })

        if (isInViewport && options.once) {
          // observer.unobserve(el)
          observer.disconnect()
        }
      }
    })
  }

  return useIntersectionObserver(target, wrappedCallback, options)
}
