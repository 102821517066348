<template>
  <section ref="root" class="pictures-slider">
    <div ref="wrapper">
      <Carousel
        ref="carousel"
        :items="content"
        :bullet-points="false"
        :prev-next="false"
      >
        <template #item="{ item }: { item: Picture }">
          <div class="js-carousel-item">
            <GPicture class="cover" :content="item" sizes="90vw" />
          </div>
        </template>
      </Carousel>

      <div class="pictures-slider__pagination">
        <Action
          :content="{
            title: 'Previous',
            icon: 'arrow-long-left',
            tag: 'button',
            modifiers: ['no-label', 'transparent'],
          }"
          class="slider__button"
          :disabled="carousel?.currentSliderIndex === 0"
          @click="carousel.prev()"
        />
        <button
          v-for="(btn, index) in content"
          :key="`pictures-slider__pagination-${index}`"
          class="pictures-slider__pagination__item"
          :class="{ 'is-active': carousel?.currentSliderIndex === index }"
          @click="carousel.goTo(index)"
        >
          <GPicture class="cover" :content="btn" sizes="60px" />
        </button>

        <Action
          :content="{
            title: 'Next',
            icon: 'arrow-long-right',
            tag: 'button',
            modifiers: ['no-label', 'transparent'],
          }"
          class="slider__button"
          :disabled="carousel?.currentSliderIndex === content.length - 1"
          @click="carousel.next()"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import type { Picture } from '@/types'
</script>

<script setup lang="ts">
import { Carousel } from '@monofront/v-components'
import { ref } from 'vue'

import type { PropType } from 'vue'

const carousel = ref()
const wrapper = ref()

defineProps({
  content: {
    type: Object as PropType<Picture[]>,
    required: true,
  },
})

const root = ref<HTMLElement>()
</script>

<style lang="scss" scoped>
.pictures-slider {
  position: relative;
  overflow: hidden;

  :deep(.picture) {
    &.is-portrait {
      img {
        object-fit: contain;
      }
    }
  }
}

:deep(.action__icon) {
  --icon-size: 4.8rem;

  transition: fill 0.2s ease-in-out;

  [class*='action--']:hover & {
    fill: $c-green-abr;
  }
}

:deep(.carousel) {
  --pagination-item-gap: 0.5rem;
  --pagination-item-size: 6rem;
  --slide-gap: 0.8rem;
  --c-pagination-item-background-active: #{$c-green-abr};
  --c-pagination-background-inactive: rgba(0, 0, 0, 10%);
  --c-scrollbar-foreground: transparent;
}

:deep(.carousel__scroller__list) {
  width: 100%;
}

:deep(.carousel__item) {
  position: relative;
  display: block;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 40/24.5;
  max-height: 70vh;
}

.pictures-slider__pagination {
  display: flex;
  justify-content: center;
  padding: $spacing * 0.5 0;
  gap: 0.4rem;

  .slider__button {
    @extend %focusable;

    padding-inline: 0;
    margin-inline: 1.6rem;

    @include mq($until: m) {
      display: none;
    }
  }
}

.pictures-slider__pagination__item {
  @extend %button-nostyle;
  @extend %focusable;

  position: relative;
  width: 6rem;
  aspect-ratio: 1;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover,
  &.is-active {
    border-bottom: 3px solid $c-green-abr;
  }
}
</style>
