import { watch } from 'vue'

import { useUiStore } from '@/stores/ui'
import { useAppear } from '@/utils/aware'

import type { MaybeElementRef } from '@vueuse/core'

/**
 * Returns a promise that will resolve when current transition has ended.
 * If no transition is running, will resolve immediatly.
 * @returns Promise<void>
 */
export const awaitPageTransition = () => {
  const ui = useUiStore()

  return new Promise<void>(resolve => {
    if (ui.hasTransition === 'none') {
      resolve()
    } else {
      const unwatch = watch(ui, newValue => {
        if (newValue.hasTransition === 'none') {
          unwatch()
          resolve()
        }
      })
    }
  })
}

/**
 * Runs callback function when target element enter viewport + no page transition is running
 * @param target
 * @param callback
 */
export function onAppear(target: MaybeElementRef, callback: () => void) {
  useAppear(
    target,
    async ({ isInViewport }) => {
      if (isInViewport) {
        await awaitPageTransition()
        callback()
      }
    },
    { once: true, rootMargin: '0px 0px -20% 0px' }
  )
}

// Check if user prefers reduced motion
export const prefersReducedMotion = () =>
  window.matchMedia('(prefers-reduced-motion: reduce)').matches

// Get header height
export const getHeaderHeight = (): number => {
  const header = document.querySelector('#app > .header')

  if (!header) {
    return 0
  }

  return header.getBoundingClientRect().height
}
