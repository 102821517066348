import { defineStore } from 'pinia'

interface UiState {
  noScroll: boolean
  hasTransition: 'page' | 'menu' | 'none'
  catalogueExpanded: boolean
  catMobExpanded: boolean
  modalVisible: boolean
  modalContent: object
  // Hoverable
  showCursorFollow: boolean
  cursorFollowX: number
  cursorFollowY: number
  cursorFollowLabel: null | string
}

const defaultState = (): UiState => ({
  noScroll: false,
  hasTransition: 'none',
  catalogueExpanded: false,
  catMobExpanded: false,
  modalVisible: false,
  modalContent: {},
  showCursorFollow: false,
  cursorFollowX: 0,
  cursorFollowY: 0,
  cursorFollowLabel: null,
})

export const useUiStore = defineStore('ui', {
  state: defaultState,
  actions: {
    toggleScroll(value?: boolean) {
      this.noScroll = value ?? !this.noScroll
      if (value === false) {
        document.documentElement.classList.add('no-scroll')
      } else {
        document.documentElement.classList.remove('no-scroll')
      }
    },
  },
})

export const hasScroll = () => window.innerWidth > document.body.clientWidth
