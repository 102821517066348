<template>
  <div ref="targetEl">
    <slot v-if="shouldRender" />
  </div>
</template>

<script setup lang="ts">
// https://medium.com/js-dojo/lazy-rendering-in-vue-to-improve-performance-dcccd445d5f
import { useIntersectionObserver } from '@vueuse/core'
import { nextTick, ref } from 'vue'

import { logger } from '@/utils/logger'

logger.warn('[Lazy.vue] This content will not be rendered on the server side')

const props = defineProps({
  renderOnIdle: {
    type: Boolean,
    default: true,
  },
})

const rootMargin = '600px'
const shouldRender = ref(false)
const targetEl = ref()

// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
const onIdle = (cb = () => {}) => {
  if (!import.meta.env.SSR && 'requestIdleCallback' in window) {
    window.requestIdleCallback(cb)
  } else {
    setTimeout(() => {
      nextTick(cb)
    }, 300)
  }
}
const { stop } = useIntersectionObserver(
  targetEl,
  ([{ isIntersecting }]) => {
    if (isIntersecting) {
      shouldRender.value = true
      stop()
    }
  },
  {
    rootMargin,
  }
)

if (props.renderOnIdle) {
  onIdle(() => {
    shouldRender.value = true
    stop()
  })
}
</script>
