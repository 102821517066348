<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="newsletter small-body-text">
    <div class="newsletter__title" v-html="content.title"></div>

    <div v-if="successMessageRef" class="newsletter__success mt-xs">
      <SvgSprite
        class="icon"
        symbol="ui-success"
        size="24"
        aria-hidden="true"
      />

      <span v-html="successMessageRef"></span>
    </div>

    <div v-else>
      <HtmlText class="newsletter__text" :content="content.text" />

      <FormBody
        class="newsletter__form"
        :content="content.form"
        @submit:success="onSubmitSuccess"
        @submit:fail="onSubmitFail"
      >
        <template #global-submit="{ loading }">
          <Action
            :content="{
              tag: 'button',
              title: content.form.settings?.sendButtonLabel,
            }"
            :disabled="loading"
            :class="{ loading }"
            type="submit"
          />
        </template>
      </FormBody>
    </div>
  </div>
</template>

<script lang="ts">
import type { FormContent } from '@monofront/bob'

interface Newsletter {
  title: string
  text: string
  form: FormContent
}
</script>

<script setup lang="ts">
import { FormBody } from '@monofront/bob'
import { ref } from 'vue'

import { push } from '@/utils/tracking'

import type { AxiosResponse } from 'axios'
import type { PropType } from 'vue'

defineProps({
  content: {
    type: Object as PropType<Newsletter>,
    required: true,
  },
})

// Vars
const successMessageRef = ref<string | null>(null)

const onSubmitSuccess = (response?: AxiosResponse) => {
  if (!response || !response.data.message) {
    console.error('No success message', response)
    successMessageRef.value = null

    return
  }

  push({
    event: 'newsletter_subscribe',
    ctaLocation: 'footer',
  })

  successMessageRef.value = response.data.message
}

const onSubmitFail = () => {
  console.log('Newsletter: submit fail')
}
</script>

<style lang="scss" scoped>
.newsletter__title {
  @extend %fw-bold;
}

.newsletter__text {
  color: $c-grey-dark;
}

.newsletter__form {
  --form-spacing: 0.5rem;

  position: relative;
  max-width: 38rem;
  margin-top: 1.2rem;

  :deep(.form-input) {
    .form-label {
      left: calc(var(--form-spacing) * 2.8);
    }

    &.filled,
    &:focus-within,
    &:has(:autofill) {
      .form-label {
        font-size: 1.1rem;
        line-height: 1.6rem;
        letter-spacing: 0.02em;
      }
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .form-element {
      padding: calc(var(--form-spacing) * 3.2) calc(var(--form-spacing) * 2.6)
        calc(var(--form-spacing) * 2);
      border: 0.1rem solid $c-grey-dark;
      border-radius: 2rem;
    }
  }

  :deep(.body__footer) {
    position: absolute;
    top: 50%;
    right: 0.4rem;
    transform: translateY(-50%);
  }
}

.newsletter__success {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 0.8rem 1.8rem 0.8rem 1.2rem;
  line-height: 1;
  background-color: $c-success-light;
  border-radius: $spacing;

  .icon {
    margin-right: 0.8rem;
    fill: $c-success;
  }
}
</style>
