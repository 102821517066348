<template>
  <figure
    v-if="content"
    ref="figure"
    :class="{
      'is-loaded': isLoaded,
      'is-portrait': content.height > content.width,
    }"
    class="picture"
  >
    <div :class="{ 'is-visible': isVisible }" class="picture__img">
      <div class="picture__placeholder"></div>
      <img
        ref="img"
        :loading="loading"
        :src="content.src"
        :alt="content.alt || ''"
        :srcset="content.sets && parseSets(content.sets)"
        :width="content.width"
        :height="content.height"
        :sizes="sizeData"
        :fetchpriority="fetchpriority"
        class="picture__image"
      />
    </div>
  </figure>
</template>

<script setup lang="ts">
import { onBeforeUnmount, onMounted, ref } from 'vue'

import { parseSets } from '@/modules/utils'
import { type Picture } from '@/types'
import { useAppear } from '@/utils/aware'

import type { PropType } from 'vue'

const props = defineProps({
  content: {
    type: Object as PropType<Picture>,
    required: true,
    default: () => ({}),
  },
  loading: {
    type: String as PropType<'lazy' | 'eager'>,
    default: 'lazy',
  },
  defaultSizes: {
    type: String,
    default: '100px',
  },
  sizes: {
    type: String,
    default: '100vw',
  },
  deferredLoading: {
    type: Number,
    default: 0,
  },
  fetchpriority: {
    type: String as PropType<'auto' | 'low' | 'high'>,
    required: false,
    default: undefined,
  },
})

const figure = ref<HTMLElement | null>(null)
const img = ref<HTMLImageElement | null>(null)
const isVisible = ref(false)
const isLoaded = ref(false)
const sizeData = ref(props.defaultSizes)
const imageWidth = ref()

const onAppear = ({ isInViewport }: { isInViewport: boolean }) => {
  img.value?.addEventListener('load', onLoaded)

  if (isInViewport) {
    isVisible.value = true
    sizeData.value = props.sizes
    imageWidth.value = props.content.width
  }
}

const onLoaded = () => {
  isLoaded.value = true
}

useAppear(figure, onAppear, { once: true })

onMounted(() => {
  if (props.deferredLoading) {
    img.value?.addEventListener('load', onLoaded)

    setTimeout(() => {
      sizeData.value = props.sizes
    }, props.deferredLoading)
  }
})

onBeforeUnmount(() => {
  img.value?.removeEventListener('load', onLoaded)
})
</script>

<style lang="scss" scoped>
.picture__image {
  filter: url('#blur');
  will-change: contents;

  .is-loaded & {
    filter: none;
  }

  .picture.cover & {
    @include image-fit;
  }
}
</style>
