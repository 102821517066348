<template>
  <UiDropdown
    v-if="langAvailable.length > 0 && options.length > 0"
    id="langSwitcher"
    class="lang"
    :options="options"
    :value="currentUrl"
    :css="styles"
    @input="onChange"
  >
    <!-- slot option + label -->
  </UiDropdown>
</template>

<script setup lang="ts">
import Cookies from 'js-cookie'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import UiDropdown from '@/components/ui/Dropdown.vue'
import { langAvailable } from '@/config/languages'
import { useChromeStore } from '@/stores/chrome'
import { useResourceStore } from '@/stores/resource'

import styles from './lang.module.scss'

import type { Language } from '@/types'

const chrome = useChromeStore()
const resource = useResourceStore()
const router = useRouter()
const options = computed(() => {
  if (resource.languages) {
    return Object.keys(resource.languages)
      .sort()
      .map(lang => {
        const value = resource.languages[lang as Language]

        return {
          label: lang,
          value,
        }
      })
  }

  return []
})

const currentUrl = computed(() => resource.languages[chrome.language])

const onChange = (url: string) => {
  const isCookiebarSet = Cookies.get('epic-cookie-prefs')

  if (url !== currentUrl.value) {
    if (isCookiebarSet) {
      router.push(url)
    } else {
      window.location.href = url
    }
  }
}
</script>

<style lang="scss" scoped>
.lang {
  margin: 1rem 1rem 1rem 4rem;

  :deep(.list) {
    z-index: 2;
    overflow: hidden;
    border: 1px solid rgba($c-black, 10%);
    border-radius: 0.4rem;
  }

  :deep(.option) {
    @extend %text-uppercase;

    padding: 0.7rem 0.9rem;
    color: $c-grey-medium;
    text-align: center;
    transition: all 0.2s ease-in-out;

    &:hover {
      color: $c-black;
      background-color: $c-beige;
    }

    &.is-active {
      color: $c-white;
      background-color: $c-green-abr;
    }
  }
}
</style>
