<template>
  <div
    v-if="langAvailable.length > 0 && options.length > 0"
    class="lang-mobile"
  >
    <div v-for="(option, label) in options" :key="`item-${label}`">
      <button
        class="lang-mobile__btn small-body-text"
        :class="{ 'is-active': currentUrl === option.value }"
        @click="onChange(option.value)"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import Cookies from 'js-cookie'
import { computed } from 'vue'
import { useRouter } from 'vue-router'

import { langAvailable } from '@/config/languages'
import { useChromeStore } from '@/stores/chrome'
import { useResourceStore } from '@/stores/resource'

import type { Language } from '@/types'

const chrome = useChromeStore()
const resource = useResourceStore()
const router = useRouter()
const options = computed(() => {
  if (resource.languages) {
    return Object.keys(resource.languages).map(lang => {
      const value = resource.languages[lang as Language]

      return {
        label: lang,
        value,
      }
    })
  }

  return []
})

const currentUrl = computed(() => resource.languages[chrome.language])

const onChange = (url: string) => {
  const isCookiebarSet = Cookies.get('epic-cookie-prefs')

  console.log('TEST', url)

  if (url !== currentUrl.value) {
    if (isCookiebarSet) {
      router.push(url)
    } else {
      window.location.href = url
    }
  }
}
</script>

<style lang="scss" scoped>
.lang-mobile {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.lang-mobile__btn {
  @extend %button-nostyle;
  @extend %text-uppercase;

  padding-inline: 1.2rem;
  height: 3.6rem;
  color: $c-grey-medium;
  background-color: $c-gray-lightest;
  border-radius: 0.8rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $c-white;
    background-color: $c-black;
  }

  &.is-active {
    color: $c-white;
    background-color: $c-green-abr;
  }
}
</style>
