import devalue from '@nuxt/devalue'
// Vue plugins
import { createHead } from '@vueuse/head'
import axios from 'axios'
import { createPinia } from 'pinia'
import { Calendar, DatePicker, setupCalendar } from 'v-calendar'
import viteSSR, { ClientOnly } from 'vite-ssr/vue'
import { readonly } from 'vue'
import { svgSpritePlugin } from 'vue-svg-sprite'

import Action from '@/components/global/Action.vue'
import GBaseline from '@/components/global/Baseline.vue'
import HtmlText from '@/components/global/HtmlText.vue'
import Lazy from '@/components/global/Lazy.vue'
import GPicture from '@/components/global/Picture.vue'
import GTitle from '@/components/global/Title.vue'
import { langDefault } from '@/config/languages'
// Vue components
import App from '@/core/App.vue'
import { ContentKey, contentRef } from '@/core/resource'
// Vue directives
import outside from '@/directives/outside'
import src from '@/directives/src'
import i18n, { setI18nLanguage } from '@/i18n'
import { createGuards } from '@/router/guards'
import routes from '@/router/routes'
import { useChromeStore } from '@/stores/chrome'
import { useNavigationStore } from '@/stores/navigation'

// import { push } from '@/utils/tracking'

import '@/styles/main.scss'
import 'virtual:svg-icons-register'

import { loadFonts } from '@/config/fonts'
import { logger } from '@/utils/logger'

import type { Language } from '@/types'

export default viteSSR(
  App,
  {
    routes,
    // https://pinia.esm.dev/ssr/#state-hydration
    transformState(state) {
      return import.meta.env.SSR ? devalue(state) : state
    },
    pageProps: {
      passToPage: false, // ALL props will be passed to the page component.
      // [Vue warn]: Extraneous non-props attributes (head) were passed to component
      // but could not be automatically inherited because component renders fragment or text root nodes.
    },
  },
  async context => {
    const { app, initialState, initialRoute } = context

    import.meta.env.VITE_RELEASE !== 'production' && console.time('font')
    if (!import.meta.env.SSR) {
      await loadFonts()
      logger.log(document.fonts.size, 'FontFaces loaded.')
    }
    import.meta.env.VITE_RELEASE !== 'production' && console.timeEnd('font')

    // Plugins
    const head = createHead()
    const pinia = createPinia()

    app.use(head)
    app.use(i18n)
    app.use(pinia)
    app.use(svgSpritePlugin, {
      url: '',
    })
    app.use(setupCalendar, {})

    // Components
    app.component(ClientOnly.name, ClientOnly)
    app.component('Action', Action)
    app.component('HtmlText', HtmlText)
    app.component('Lazy', Lazy)
    app.component('GPicture', GPicture)
    app.component('GTitle', GTitle)
    app.component('GBaseline', GBaseline)
    app.component('VCalendar', Calendar)
    app.component('VDatePicker', DatePicker)

    // Directives
    app.directive('outside', outside)
    app.directive('src', src)

    // Other…
    app.provide(ContentKey, readonly(contentRef))

    // Set default language
    const lang = (initialRoute.params.lang as Language) || langDefault
    axios.defaults.headers.common['Accept-Language'] = lang

    createGuards(context, pinia)

    if (import.meta.env.SSR) {
      // This will be stringified and set to window.__INITIAL_STATE__
      initialState.pinia = pinia.state.value
    } else {
      // On the client side, we restore the state.
      // This should be done before calling any useStore() function on client side !!!
      pinia.state.value = initialState.pinia

      const chrome = useChromeStore(pinia)
      const navigation = useNavigationStore(pinia)

      // Set locales on the client side
      setI18nLanguage(lang, chrome.i18n, false)
      navigation.referrer = document.referrer
    }

    return { head }
  }
)
