<template>
  <div ref="outerCursorRef" class="cursor-follow">
    <div
      ref="innerCursorRef"
      class="cursor-follow__inner"
      :class="{
        'no-label': !ui.cursorFollowLabel,
      }"
    >
      <div
        v-if="ui.cursorFollowLabel"
        :key="ui.cursorFollowLabel"
        ref="cursorLabel"
        class="cursor-follow__label"
        aria-hidden="true"
      >
        {{ ui.cursorFollowLabel }}
      </div>

      <SvgSprite
        v-else
        class="icon"
        symbol="ui-eye"
        size="40"
        aria-hidden="true"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import gsap from 'gsap'
import { lerp } from 'math-toolbox'
import { onMounted, onUnmounted, onUpdated, ref, watch } from 'vue-demi'

import { useUiStore } from '@/stores/ui'

import type { Ref } from 'vue'

const ui = useUiStore()

const innerCursorRef: Ref<HTMLElement | null> = ref(null)
const outerCursorRef: Ref<HTMLElement | null> = ref(null)

const cursorLabel = ref()
const splitCursorLabel = ref()

const LERP_AMOUNT = 0.5

const mouse = {
  target: {
    x: 0,
    y: 0,
  },
  lerped: {
    x: 0,
    y: 0,
  },
}

const onMouseMove = ({ clientX, clientY }: MouseEvent) => {
  mouse.target.x = clientX
  mouse.target.y = clientY
}

const onTick = () => {
  const x = lerp(mouse.lerped.x, mouse.target.x, LERP_AMOUNT)
  mouse.lerped.x = x
  outerCursorRef.value!.style.setProperty('--mouse-x', `${mouse.lerped.x}px`)

  const y = lerp(mouse.lerped.y, mouse.target.y, LERP_AMOUNT)
  mouse.lerped.y = y
  outerCursorRef.value!.style.setProperty('--mouse-y', `${mouse.lerped.y}px`)
}

watch(
  () => ui.showCursorFollow,
  hover => {
    if (innerCursorRef.value) {
      gsap.killTweensOf(innerCursorRef)
      gsap.to(innerCursorRef.value, {
        '--scale': hover ? 1 : 0,
        // eslint-disable-next-line quote-props
        ease: 'power4.out',
        // eslint-disable-next-line quote-props
        duration: 1,
      })
      if (splitCursorLabel.value) {
        gsap.to(splitCursorLabel.value, {
          duration: 0.5,
          stagger: 0.05,
          opacity: hover ? '1' : '0',
          y: hover ? '0' : '20',
          ease: 'power4.out',
        })
      }
    }

    if (ui.showCursorFollow) {
      gsap.ticker.add(onTick)
    } else {
      gsap.ticker.remove(onTick)
    }
  }
)

onMounted(() => {
  document.addEventListener('mousemove', onMouseMove)
})

onUpdated(() => {
  if (ui.cursorFollowLabel) {
    if (splitCursorLabel.value) {
      splitCursorLabel.value.revert()
    }
  }
})

onUnmounted(() => {
  document.removeEventListener('mousemove', onMouseMove)
})

defineExpose({
  innerCursorRef,
  cursorFollowLabel: ui.cursorFollowLabel,
})
</script>

<style lang="scss" scoped>
.cursor-follow {
  display: none;
  cursor: grab;

  @media (hover: hover) and (pointer: fine) {
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
    transform: translate(var(--mouse-x), var(--mouse-y));
    transform-origin: 50% 50%;
  }
}

.cursor-follow__inner {
  --scale: 0;

  @extend %fw-bold;

  cursor: grab;
  position: relative;
  padding: 1rem 2rem;
  color: $c-white;
  font-size: 1.6rem;
  text-align: center;
  background-color: $c-black;
  border-radius: 4rem;
  transform: translate(20%, 20%) scale(var(--scale));
  transform-origin: 50% 50%;

  &.no-label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    aspect-ratio: 1;
    padding: 0;

    svg {
      fill: $c-white;
    }
  }
}
</style>
