import { langAvailable, langDefault } from '@/config/languages'
import Root from '@/views/Root.vue'

import type { Component } from 'vue'
import type { RouteRecordRaw } from 'vue-router'

// Route segments
export const segments = {
  // foo: ['foo-fr', 'foo-nl'],
  // bar: 'bar-bar',
  showroom: ['showroom'],
  legacy: ['heritage', 'heritage', 'legacy'],
  services: ['services', 'diensten', 'services'],
  news: ['magazine', 'news', 'news'],
  // catalogue: ['catalogue'],
} as Record<string, string | string[]>

// Format translated route segments
// formatSegment('foo') => ':segment(foo-fr|foo-nl)'
// formatSegment('bar', 'qux) => ':qux(bar-bar)'
const formatSegment = (segmentKey: string, paramName = 'segment'): string =>
  Array.isArray(segments[segmentKey])
    ? `:${paramName}(${(segments[segmentKey] as string[]).join('|')})`
    : `:${paramName}(${segments[segmentKey] as string})`

// Routes + endpoints examples…
// 1. segment is not translated and is the same as the endpoint name
// {
//   path: '/same-name/',
//   meta: { endpoint: 'same-name' },
// },
//
// 2. segment is translated
// {
//   path: `/${formatSegment('name')}/`,
//   meta: { endpoint: 'name' },
// },
//
// 3. segment not the same as the endpoint name
// @example https://site.com/segment-name/ => https://cms.com/api/v1/endpoint-name
// {
//   path: '/:pattern(segment-name)/',
//   meta: { endpoint: 'endpoint-name' },
// },

let routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: () => import('@/views/Homepage.vue'),
    name: 'homepage',
    // meta: {
    //   static: true,                  // No automatic call to API (WP)
    //   endpoint: 'posts',             // Specific endpoint
    //   api: 'http://some-other-api',  // Use different API address
    //   key: 'id',                     // Avoid component re-rendering
    //   root: true,                    // Root route even with languages
    // },
  },
  {
    path: '/dev',
    component: () => import('@/views/Dev.vue'),
    name: 'dev',
    meta: {
      static: true,
      chrome: false,
    },
  },
  // News
  {
    path: `/${formatSegment('news')}/`,
    component: () => import('@/views/Archive.vue'),
    meta: {
      key: 'news',
    },
    children: [
      {
        path: '',
        name: 'news-archives',
        component: () => import('@/components/archives/Content.vue'),
        meta: {
          key: 'news',
        },
      },
      {
        path: 'page/:pageNumber/',
        name: 'news-archives-paged',
        component: () => import('@/components/archives/Content.vue'),
        meta: {
          key: 'news',
          archive: true,
          pathToRegexpOptions: { strict: true },
        },
      },
    ],
  },
  {
    path: `/${formatSegment('news')}/:slug/`,
    component: () => import('@/views/pages/Single.vue'),
    name: 'news',
    meta: {
      endpoint: 'news',
    },
  },
  // Catalogue
  {
    path: '/b/:slug/',
    component: () => import('@/views/pages/Brands.vue'),
    name: 'brands',
    meta: {
      endpoint: 'brand',
    },
  },
  {
    path: '/e/:slug/',
    component: () => import('@/views/pages/Rooms.vue'),
    name: 'rooms',
    meta: {
      endpoint: 'room',
    },
  },
  {
    path: '/e/:slug/:subslug',
    component: () => import('@/views/pages/Subcategories.vue'),
    name: 'rooms-sub',
    meta: {
      endpoint: 'subcategory',
    },
  },
  {
    path: '/c/:slug/',
    component: () => import('@/views/pages/Rooms.vue'),
    name: 'categories',
    meta: {
      endpoint: 'category',
    },
  },
  {
    path: '/c/:slug/:subslug/',
    component: () => import('@/views/pages/Subcategories.vue'),
    name: 'categories-sub',
    meta: {
      endpoint: 'subcategory',
    },
  },
  {
    path: '/sc/:slug/:subslug?',
    component: () => import('@/views/pages/Subcategories.vue'),
    name: 'subcategories',
    meta: {
      endpoint: 'subcategory',
    },
  },
  {
    path: '/:page/',
    component: () => import('@/views/Page.vue'),
    name: 'page',
  },
  {
    path: '/:catchAll(.*)',
    name: 'catch-all',
    component: () => import('@/views/404.vue'),
  },
]

// If multilanguage, add lang segment
if (langAvailable.length > 1) {
  const initialRoutes = [...routes]

  routes = [
    {
      path: `/:lang(${langAvailable.join('|')})`,
      component: Root as Component,
      meta: {
        // ScrollTop all the time for all routes
        scrollTop: true,
      },
      children: initialRoutes
        // Remove catch-all route (there should be only one)
        // and "root" routes (that will stay at the root level)
        .filter(r => r.name !== 'catch-all' && r.meta?.root !== true)
        .map(r => {
          // Remove leading slash
          r.path = r.path.replace(/^\//, '')

          return r
        }),
    },
    {
      path: '/',
      redirect: () => `/${langDefault}/`, // `/${navigator.language.split('-')[0] || langDefault}`
    },
    {
      path: '/:catchAll(.*)',
      name: 'catch-all',
      component: () => import('@/views/404.vue'),
    },
  ]

  // Keep "root" routes
  routes = routes.concat(initialRoutes.filter(r => r.meta?.root === true))
}

export default routes
