<!-- eslint-disable vue/no-v-html -->
<template>
  <header
    class="header"
    :class="{
      visible: isVisibleRef,
      'is-light': pagesLight || isLightRef || uiStore.catalogueExpanded,
    }"
  >
    <div class="header-secondary">
      <div class="header-secondary-inner wrapper small-body-text">
        <div class="header-secondary__contact">
          <SvgSprite
            class="icon"
            symbol="ui-address"
            size="14"
            aria-hidden="true"
          />
          <span>
            <a :href="chrome.contact.addressUrl" target="_blank">
              {{ chrome.contact.address }}
            </a>
          </span>
        </div>
        <div class="header-secondary__contact">
          <SvgSprite
            class="icon"
            symbol="ui-phone"
            size="14"
            aria-hidden="true"
          />
          <span>
            <a :href="chrome.contact.phone.url">
              {{ chrome.contact.phone.title }}
            </a>
          </span>
        </div>
        <ul class="header-secondary__nav">
          <li
            v-for="(item, index) in chrome.menus.top"
            :key="`menu-main-secondary-${index}`"
            class="header-secondary__nav__item"
          >
            <RouterLink class="header-secondary__nav__link" :to="item.url">
              <span v-html="item.title"></span>
            </RouterLink>
          </li>
        </ul>
        <ChromeLang />
      </div>
    </div>
    <div
      class="header-primary"
      :class="[
        {
          active: uiStore.catalogueExpanded,
        },
      ]"
    >
      <div class="wrapper">
        <div class="header-primary-inner">
          <RouterLink
            :to="langAvailable.length > 1 ? `/${locale}/` : '/'"
            class="header-primary__logo"
          >
            <img src="@/assets/svg/logo/logo.svg" alt="au bon repos" />
          </RouterLink>

          <nav>
            <ul class="header-primary__nav">
              <li
                v-for="(item, index) in chrome.menus.main"
                :key="`menu-main-primary-${index}`"
                class="header-primary__nav__item pt-xxs pb-xxs"
              >
                <CatalogueMenu v-if="item.children" :content="item" />
                <RouterLink
                  v-else
                  class="header-primary__nav__link"
                  :to="item.url"
                >
                  <span v-html="item.title"></span>
                </RouterLink>
              </li>
            </ul>
          </nav>

          <Action
            v-if="chrome.cta"
            :content="{
              ...chrome.cta,
              icon: 'calendar',
              modifiers: ['reversed'],
            }"
            class="header-primary__cta"
            @click="onClick()"
          />

          <ChromeMegaMenu class="header-primary__trigger" />
        </div>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { gsap } from 'gsap'
import { Observer } from 'gsap/Observer'
import { computed, onBeforeUnmount, onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'

import CatalogueMenu from '@/components/chrome/CatalogueMenu.vue'
import ChromeLang from '@/components/chrome/Lang.vue'
import ChromeMegaMenu from '@/components/chrome/MegaMenu.vue'
import { langAvailable } from '@/config/languages'
import { useChromeStore } from '@/stores/chrome'
import { useResourceStore } from '@/stores/resource'
import { useUiStore } from '@/stores/ui'
import { push } from '@/utils/tracking'

const { locale } = useI18n({ useScope: 'global' })
const chrome = useChromeStore()
const uiStore = useUiStore()
const resource = useResourceStore()

gsap.registerPlugin(Observer)

let observer: Observer

const isVisibleRef = ref(true)
const isLightRef = ref(false)
const isTop = ref(true)

const pagesLight = computed(
  () =>
    resource.template === 'faq' ||
    resource.template === 'contact' ||
    resource.template === 'magazine' ||
    resource.template === 'news' ||
    resource.template === 'brands' ||
    resource.type === 'not_found_decorator' ||
    resource.type === 'subcategory_decorator'
)

const onClick = () => {
  push({
    event: 'contact_cta_click',
    ctaLocation: 'header',
  })
}

onMounted(() => {
  observer = Observer.create({
    target: window,
    type: 'scroll',
    onUp: o => {
      if (o.scrollY() >= 0) {
        isVisibleRef.value = true
        isLightRef.value = true
      }

      if (o.scrollY() < 130) {
        isTop.value = true
        isLightRef.value = false
      }
    },
    onDown: o => {
      if (o.scrollY() > 130) {
        isVisibleRef.value = false
        isTop.value = false
      }
    },
  })
})

onBeforeUnmount(() => {
  observer?.kill()
})
</script>

<style lang="scss" scoped>
$custom-spacing: 0.8rem;

.header,
[class*='header--'] {
  position: fixed;
  z-index: $header-z-index;
  top: 0;
  right: 0;
  left: 0;
  transform: translateY(-$header-height);
  transition: transform 0.2s ease-in-out;

  // Prevent layout shift in fixed header when scrollbar disapear
  .no-scroll & {
    right: var(--scrollbar-width);
  }

  &.visible {
    transform: translateY(0);
  }

  @include mq($until: m) {
    height: 6rem;
    background-color: $c-white;
    transform: translateY(-$header-height-mobile);
  }

  @include mq(m) {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0%) 0%,
      rgba(0, 0, 0, 60%) 60%
    );
  }
}

.header-secondary {
  background-color: $c-white;

  @include mq($until: m) {
    display: none;
  }
}

.header-secondary-inner {
  display: flex;
  align-items: center;
  min-height: $spacing * 2;
}

.header-secondary__contact {
  display: flex;
  align-items: center;
  margin-right: $spacing;

  .icon {
    margin-right: 0.4rem;
    fill: $c-green-light;
  }

  a {
    color: $c-black;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }
}

.header-secondary__nav,
.header-primary__nav {
  @extend %list-nostyle;

  display: flex;
  align-items: center;
  margin-left: auto;
}

.header-secondary__nav__item {
  margin-right: 3rem;
}

.header-secondary__nav__link {
  color: $c-black;
  transition: opacity 0.2s ease-in-out;

  &:hover {
    opacity: 0.6;
  }
}

:deep(.lang) {
  margin: 0;

  .select {
    min-width: 4rem;
  }

  .trigger {
    padding-inline: 0;
    text-transform: uppercase;
  }
}

.header-primary {
  background-color: transparent;
  transition: background 0.2s ease-in-out;

  .is-light &,
  &.active,
  &:hover {
    background: $c-white;
  }
}

.header-primary-inner {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: $custom-spacing;
}

.header-primary__nav {
  @include mq($until: m) {
    display: none;
  }
}

.header-primary__logo {
  position: absolute;
  z-index: 2;
  top: $custom-spacing;
  left: 0;
  height: 10rem;

  img {
    height: 100%;
  }

  @include mq($until: m) {
    z-index: 0;
    top: 0.4rem;
    height: 6rem;
  }
}

.header-primary__cta {
  position: absolute;
  top: calc($spacing + $custom-spacing);
  right: 0;

  @include mq($until: m) {
    display: none;
  }
}

.header-primary__nav__item {
  @extend %fw-semi;

  margin-block: 1.65rem;
  margin-inline: $spacing;

  span {
    font-size: 2rem;
    line-height: 3rem;
  }
}

.header-primary__nav__link {
  @extend %text-nodecoration;

  position: relative;
  display: inline-block;
  color: $c-white;
  transition: color 0.2s ease-in-out;

  &::before {
    position: absolute;
    bottom: -0.2rem;
    left: 50%;
    width: 50%;
    height: 0.2rem;
    content: '';
    transform: translateX(-50%);
    background-color: $c-green-abr;
    opacity: 0;
    transition: all 0.2s ease-in-out;
  }

  &:hover {
    color: $c-green-abr;

    &::before {
      width: 100%;
      opacity: 1;
    }
  }

  .is-light &,
  .header-primary:hover & {
    color: $c-black;
  }
}

.header-primary__trigger {
  position: absolute;
  top: 0.8rem;
  right: 0;

  @include mq(m) {
    display: none;
  }
}
</style>
