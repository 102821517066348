<template>
  <div
    v-if="isActive && envs && currents"
    :class="[{ 'is-open': isOpen }, `env-switcher--${position}`]"
  >
    <div class="env-switcher__actions">
      <button type="button" class="env-switcher__toggler" @click="onToggle">
        <svg
          width="13px"
          height="11px"
          viewBox="0 0 13 11"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <title>Env toggler</title>
          <g stroke="none" fill-rule="evenodd">
            <polygon
              transform="translate(8.500000, 5.500000) rotate(-270.000000) translate(-8.500000, -5.500000) "
              points="8.5 3 12 8 5 8"
            ></polygon>
            <path
              d="M0,11 L0,0 L13,0 L13,11 L0,11 Z M3,1 L1,1 L1,10 L3,10 L3,1 Z M12,1 L4,1 L4,10 L12,10 L12,1 Z"
            ></path>
          </g>
        </svg>
      </button>
      <button
        v-if="hasChanges"
        type="button"
        class="env-switcher__confirm"
        @click="onConfirm"
      >
        <span role="img" aria-label="confirm">✔︎</span>
      </button>
    </div>
    <div v-if="envs && currents" class="env-switcher__content">
      <template v-for="(env, label) in envs" :key="`item-${label}`">
        <label for="label" class="env-switcher__content__label">{{
          label.toUpperCase()
        }}</label>
        <select
          id="label"
          v-model="currents[label]"
          class="env-switcher__content__select"
          @blur="onBlur(label)"
          @change="onChange(label)"
        >
          <option v-for="(name, cookie) in env" :key="cookie" :value="cookie">
            {{ name }}
          </option>
        </select>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import axios from 'axios'
import Cookies from 'js-cookie'
import { onBeforeMount, ref } from 'vue'

import { logger } from '@/utils/logger'

const positions = ['right', 'left'] as const

type EnvsData = Record<string, Record<string, string>>
type EnvsValues = Record<string, string>
type EnvsPosition = (typeof positions)[number]

defineProps({
  position: {
    type: String as () => EnvsPosition,
    default: 'right',
    validator(pos: string) {
      // Duplicate…
      const positions = ['right', 'left'] as const

      return positions.includes(pos as EnvsPosition)
    },
  },
})

/* eslint-disable indent */
const getCookieOptions = (): Cookies.CookieAttributes => ({
  domain:
    import.meta.env.MODE === 'development'
      ? 'localhost'
      : window.location.hostname.replace(
          /^(.+)(\.[a-zA-z0-9-]{2,}\.[a-zA-z0-9-]{2,})$/,
          '$2'
        ),
  secure: true,
  sameSite: 'none',
  path: '/',
})
/* eslint-enable indent */

const isActive = import.meta.env.MODE === 'staging'
const isOpen = ref(false)
const envs = ref<EnvsData>({})
const currents = ref<EnvsValues>({})
const initial = ref<EnvsValues>({})
const hasChanges = ref(false)
let cookieOptions: Cookies.CookieAttributes = {}

const checkChanges = (label: string) => {
  if (initial.value[label] !== currents.value[label]) {
    hasChanges.value = true
  }
}

const onToggle = () => {
  isOpen.value = !isOpen.value

  if (!isOpen.value && hasChanges.value) {
    // Reset to initial values
    Object.keys(initial.value).forEach(label => {
      currents.value[label] = initial.value![label]
    })
    hasChanges.value = false
  }
}
const onBlur = checkChanges
const onChange = checkChanges

const onConfirm = () => {
  if (!hasChanges.value) {
    return
  }

  Object.keys(currents.value).forEach(label => {
    const value = currents.value[label]

    if (value !== initial.value?.[label]) {
      const cookieName = `epic_env_${label}`

      Cookies.set(cookieName, value, cookieOptions)
      hasChanges.value = true
    }
  })

  isOpen.value = false
  hasChanges.value = false
  initial.value = { ...currents.value }

  window.location.reload()
}

onBeforeMount(async () => {
  if (!isActive) {
    return
  }

  cookieOptions = getCookieOptions()

  try {
    await axios.get('/envs.json').then(res => {
      const { data } = res
      const currentsData: EnvsValues = {}

      envs.value = { ...data }

      Object.keys(envs.value).forEach(label => {
        const cookieName = `epic_env_${label}`

        if (Cookies.get(cookieName)) {
          currentsData[label] = Cookies.get(cookieName) as string
        } else {
          currentsData[label] = envs.value[label].staging
            ? 'staging'
            : Object.keys(envs.value[label])[0]
        }

        Cookies.set(cookieName, currentsData[label], cookieOptions)
      })

      currents.value = currentsData
      initial.value = { ...currents.value }
    })
  } catch (error) {
    logger.warn('No ENVS found')
  }
})
</script>

<style lang="scss" scoped>
$w: 1.3rem;
$h: 1.1rem;
$s: 1.5;
$p: 0.6rem;
$bg: $c-gray-darkest;
$c: $c-gray-medium;

.env-switcher,
[class*='env-switcher--'] {
  position: fixed;
  z-index: 9999;
  right: 0;
  bottom: 0;
  display: flex;
  padding: 0 $p 0 0;
  color: $c;
  background-color: $bg;
  transform: translateX(calc(100% - #{(($w * $s) + (2 * $p))}));

  &.is-open {
    /* stylelint-disable-next-line declaration-no-important */
    transform: translateX(0) !important;
  }
}

[class*='env-switcher--'][class*='--left'] {
  right: auto;
  left: 0;
  flex-direction: row-reverse;
  padding: 0 0 0 $p;
  transform: translateX(calc(-100% + #{(($w * $s) + (2 * $p))}));
}

.env-switcher__confirm,
.env-switcher__toggler {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: $p;
  color: $c;
  line-height: 1;
  background-color: $bg;
  border: none;
  cursor: pointer;

  &:hover {
    color: color.adjust($c, $lightness: 20%);
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.env-switcher__toggler {
  fill: currentcolor;

  svg {
    width: $w * $s;
    height: $h * $s;
    fill: inherit;
  }
}

/* stylelint-disable-next-line no-descending-specificity */
.env-switcher__confirm {
  width: 100%;
  padding-top: $p * 0.8;
  padding-bottom: $p * 0.8;
}

.env-switcher__content {
  display: grid;
  grid-template-columns: auto auto;
  gap: $p;
  margin: 0;
  padding: $p;
  border: 0 dotted $c;
  border-width: 0 0 0 1px;

  [class*='env-switcher--'][class*='--left'] & {
    border-width: 0 1px 0 0;
  }
}

.env-switcher__content__label,
.env-switcher__content__select {
  font-size: 0.8em;
}

.env-switcher__content__select {
  color: $c;
  background-color: $bg;
  border: 1px solid $c;
  border-radius: 4px;
}
</style>
