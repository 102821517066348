<template>
  <ChromeHeader />
  <main class="page">
    <RouterView v-slot="{ Component }">
      <Suspense>
        <PageTransition mode="out-in" @before-leave="onBeforeLeave">
          <component
            :is="resource.template === 'notfound' ? Notfound : Component"
            :key="(route.meta.key as string) || route.fullPath"
            :content="resource.content"
          />
        </PageTransition>
      </Suspense>
    </RouterView>
  </main>
  <ChromeFooter />

  <Transition name="g-fade">
    <div v-show="uiStore.catalogueExpanded" class="overlayer"></div>
  </Transition>

  <Transition name="g-fade">
    <Modal v-if="uiStore.modalVisible"></Modal>
  </Transition>

  <CursorFollow v-if="route.query.dev === undefined" />
  <!-- dev zone -->
  <DevEnvSwitcher />
  <DevGrid />
</template>

<script setup lang="ts">
import '@monofront/v-components/style.css'

import { useHead, useHeadRaw } from '@vueuse/head'
import Vlitejs from 'vlitejs'
import VlitejsYoutube from 'vlitejs/dist/providers/youtube'
import { computed, onBeforeMount, onMounted } from 'vue'
import { useRoute } from 'vue-router'

import PageTransition from '@/animations/PageTransition.vue'
import ChromeFooter from '@/components/chrome/Footer.vue'
import ChromeHeader from '@/components/chrome/Header.vue'
import DevEnvSwitcher from '@/components/dev/EnvSwitcher.vue'
import DevGrid from '@/components/dev/Grid.vue'
import CursorFollow from '@/components/ui/CursorFollow.vue'
import Modal from '@/components/ui/Modal.vue'
import { googleSite } from '@/config/app'
import favicon from '@/config/favicon'
import { prefers } from '@/core/prefers'
import { updateResource } from '@/core/resource'
import { useChromeStore } from '@/stores/chrome'
import { useResourceStore } from '@/stores/resource'
import { useUiStore } from '@/stores/ui'
import { useViewport } from '@/utils/aware'
import { logger } from '@/utils/logger'
import Notfound from '@/views/404.vue'

import type { ResourceHead, ResourceSingle } from '@/stores/resource'

const chrome = useChromeStore()
const resource = useResourceStore()
const route = useRoute()
const uiStore = useUiStore()

useViewport()

logger.log('App setup')

// First rendering, SSR
if (import.meta.env.SSR && route.meta.state) {
  updateResource(route.meta.state.resource as ResourceSingle)
}

/**
 * User prefs
 */
if (!import.meta.env.SSR) {
  prefers.init()
  console.log('prefers', prefers)
}

/**
 * Head
 */
const title = computed(
  () => resource.head?.title || resource.content?.title || ''
)
const meta = computed(() => {
  const meta = [...(resource.head?.meta || []), ...favicon.meta]

  meta.push({ name: 'environment', content: import.meta.env.VITE_RELEASE })

  googleSite &&
    meta.push({ name: 'google-site-verification', content: googleSite })

  return meta
})

const preload: ResourceHead['style'] = []

// Add resources to preload (ex: hero image, visible into the viewport, …)
// if (resource.content.hero) {
//   preload.push({
//     rel: 'preload',
//     as: 'image',
//     href: resource.content.hero.picture.sets[2560],
//     // media: '(max-width: 600px)',
//     imagesrcset: `
//         ${resource.content.hero.picture.sets[2560]} 2560w,
//         ${resource.content.hero.picture.sets[1280]} 1280w,
//         ${resource.content.hero.picture.sets[960]} 960w`,
//     imagesizes: '100vw',
//     crossorigin: true,
//   })
// }

const link = computed(() => {
  const link = [...(resource.head?.link || []), ...favicon.link]

  return (
    link
      // Preconnect CMS
      // <link rel="preconnect" href="https://foo.com/" crossorigin>
      .concat({
        rel: 'preconnect',
        href: import.meta.env.VITE_API_DOMAIN,
        crossorigin: true,
      })
      .concat(preload)
  )
})

// LD=JSON as it will be used with `useHeadRaw()`
const scriptRaw = computed(() => {
  const headScripts: ResourceHead['script'] = resource.head?.script || []
  const chromeScripts: ResourceHead['script'] = chrome.script || []

  const mergedScripts = [
    ...chromeScripts.filter(
      s => s.type === 'application/ld+json' || s.innerHTML
    ),
    ...headScripts.filter(s => s.type === 'application/ld+json' || s.innerHTML),
    {
      src: 'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback',
    },
  ]

  return mergedScripts
})

const script = computed(() => {
  const headScripts: ResourceHead['script'] = resource.head?.script || []
  const chromeScripts: ResourceHead['script'] = chrome.script || []

  const mergedScripts = [
    ...chromeScripts.filter(
      s => s.type !== 'application/ld+json' && !s.innerHTML
    ),
    ...headScripts.filter(
      s => s.type !== 'application/ld+json' && !s.innerHTML
    ),
  ]

  return mergedScripts
})

useHead({
  title,
  link,
  script,
  htmlAttrs: () => resource.head?.htmlAttrs,
  bodyAttrs: () => resource.head?.bodyAttrs,
})
useHeadRaw({
  meta, // using raw for meta avoid ' to be escaped on SSR rendering…
  script: scriptRaw,
})

/**
 * Lifecycle
 */
onBeforeMount(() => {
  // Video provider needs to be registered only once
  Vlitejs.registerProvider('youtube', VlitejsYoutube)
})

onMounted(() => {
  logger.log('App mounted')
  const event = new Event('appready')
  setTimeout(() => document.dispatchEvent(event), 0)
})

const onBeforeLeave = () => {
  if (route.meta.state) {
    // Update state during the transition
    // when the previous view is leaving to avoid content errors.
    if (route.meta.state) {
      updateResource(route.meta.state.resource as ResourceSingle)
    }
  }

  uiStore.showCursorFollow = false
}
</script>

<style lang="scss">
/* stylelint-disable selector-max-id */
#app {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
}

.overlayer {
  @include get-all-space;

  position: fixed;
  z-index: 5;
  background-color: rgba($c-black, 70%);
}
</style>
