/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from 'pinia'

import type { Head, MergeHead } from '@zhead/schema'
import type { AxiosError } from 'axios'
import type { Languages } from '@/types'

export interface Content {
  title: string
  // components?: any[]
}

export interface Resource {
  // content: null | Content | Content[]
  content: Record<string, never> | Content
}

export interface ResourceHead extends Head<MergeHead> {
  description?: string
}

export interface ResourceSingle extends Resource {
  id: number
  head: ResourceHead
  languages: Languages
  template: string
  slug: string
  type: string
  url: string

  i18n?: Record<string, any>
}

export type ResourceError = Error | AxiosError

// export interface ResourceArchive extends Resource {
//   pages: number
//   currentPage: number
//   perPage: number
//   totalPosts: number
//   isLastPage: boolean
// }

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ResourceState extends ResourceSingle {}
const defaultState = (): ResourceSingle =>
  ({
    content: {},
    id: 0,
    languages: {} as Languages,
    head: {} as ResourceHead,
    slug: 'home',
    template: 'homepage',
    type: 'homepage_decorator',
    url: '/',
  }) as ResourceSingle

export const useResourceStore = defineStore('resource', {
  state: defaultState,
})
