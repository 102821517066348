/* eslint-disable lines-around-comment */
import { useBreakpoints } from '@vueuse/core'
import { tryOnScopeDispose } from '@vueuse/shared'
import { reactive, watch } from 'vue'

import data from '@/data/breakpoints.json'

import type { Ref } from 'vue'

const reactiveChecks = ['greater', 'smaller', 'between'] as const

type StaticCheck = 'isGreater' | 'isSmaller' | 'isInBetween'
type ReactiveCheck = (typeof reactiveChecks)[number]

export type ResponsiveCallback = (isMatching: boolean) => void
export interface ResponsiveOptions {
  /**
   * Start the responsive callback immediately on creation
   *
   * @default true
   */
  immediate?: boolean
  // TODO: add support for `equals`
  // equals?: boolean
}
export type ResponsiveLabel = keyof typeof data

const breakpoints = useBreakpoints(data)

/**
 * Overload 1: Static check
 *
 * @see https://vueuse.org/useResponsive
 * @param check
 * @param labels
 * @param options
 */
export function useResponsive(
  check: StaticCheck,
  labels: ResponsiveLabel | ResponsiveLabel[],
  options?: ResponsiveOptions
): boolean

/**
 * Overload 2: Reactive check, no watch
 *
 * @see https://vueuse.org/useResponsive
 * @param check
 * @param labels
 * @param options
 */
export function useResponsive(
  check: ReactiveCheck,
  labels: ResponsiveLabel | ResponsiveLabel[],
  options?: ResponsiveOptions
): Ref<boolean>

/**
 * Overload 3: Reactive check with callback for watching
 *
 * @see https://vueuse.org/useResponsive
 * @param check
 * @param labels
 * @param callback
 * @param options
 */
export function useResponsive(
  check: ReactiveCheck,
  labels: ResponsiveLabel | ResponsiveLabel[],
  callback?: ResponsiveCallback,
  options?: ResponsiveOptions
): { stop: () => void; status: boolean }

// Implementation signature
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useResponsive(...args: any[]) {
  let check: StaticCheck | ReactiveCheck
  let labels: ResponsiveLabel[]
  let options: ResponsiveOptions
  let callback: ResponsiveCallback | undefined

  const defaultOptions: ResponsiveOptions = { immediate: true }

  if (typeof args[2] === 'function') {
    ;[check, labels, callback, options = defaultOptions] = args
  } else {
    ;[check, labels, options = defaultOptions] = args
  }

  const [a, b] = Array.isArray(labels) ? labels : [labels]
  const { immediate } = options

  let ref: Ref<boolean>

  switch (check) {
    // Static checks. Returns the value directly.
    case 'isInBetween':
      return breakpoints[check](a, b)
    case 'isGreater':
    case 'isSmaller':
      return breakpoints[check](a)
    // Reactive and watchable checks. We need a ref.
    case 'between':
      ref = breakpoints[check](a, b)
      break
    case 'greater':
    case 'smaller':
    default:
      ref = breakpoints[check](a) as Ref<boolean>
      break
  }

  if (callback && reactiveChecks.includes(check)) {
    const stopWatch = watch(ref, callback)

    const stop = () => {
      stopWatch()
    }

    tryOnScopeDispose(stop)

    if (immediate) {
      callback(ref.value)
    }

    return reactive({
      status: ref,
      stop,
    })
  }

  return ref
}
