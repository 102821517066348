<!-- eslint-disable vue/no-v-html -->
<template>
  <footer class="footer mt-xxxl">
    <div class="footer-inner wrapper">
      <div class="footer__action">
        <ul v-if="chrome.menus.footer" class="footer__menu">
          <li
            v-for="(item, index) in chrome.menus.footer"
            :key="`footer-menu-item-${index}`"
            class="footer__menu__item"
          >
            <component
              :is="item.url.startsWith('http') ? 'a' : 'router-link'"
              v-if="item.url"
              :key="item.url"
              :to="item.url"
              :href="item.url"
              :target="item.target"
              class="footer__menu__item__link"
              @click="onClickContact(item)"
            >
              <span v-html="item.title"></span>
            </component>
          </li>
        </ul>

        <ul class="footer__some">
          <li
            v-for="(item, index) in chrome.socialNetworks"
            :key="`some-${index}`"
            class="footer__some__item"
          >
            <a
              v-if="item.url"
              :href="item.url"
              target="_blank"
              :aria-label="item.title"
              class="footer__some__item__link"
              @click="onClick(item.url)"
            >
              <SvgSprite
                class="icon"
                :symbol="`social-${item.title}`"
                size="18"
                aria-hidden="true"
              />
            </a>
          </li>
        </ul>

        <FormNewsletter
          class="footer__newsletter"
          :content="chrome.newsletter as Newsletter"
        ></FormNewsletter>
      </div>

      <ShowroomInfos
        class="footer__showroom"
        :content="chrome.showroomInfos"
      ></ShowroomInfos>
    </div>

    <div class="footer__legal wrapper">
      <ul v-if="chrome.menus.legals" class="footer__legal__list tiny-body-text">
        <li
          v-for="(item, index) in chrome.menus.legals"
          :key="`footer-legal-item-${index}`"
        >
          <a
            v-if="item.classes === 'cookies'"
            class="footer__legal__list__item"
            href="javascript: Cookiebot.renew()"
          >
            {{ item.title }}
          </a>
          <RouterLink v-else :to="item.url" class="footer__legal__list__item">
            {{ item.title }}
          </RouterLink>
        </li>
        <li>
          <a
            class="footer__legal__epic"
            href="https://www.epic.net/en/"
            target="_blank"
            aria-label="epic agency"
          >
            <SvgSprite class="icon" symbol="epic-logo" size="0 0 15 15" />
          </a>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script lang="ts">
import type { FormContent } from '@monofront/bob'

interface Newsletter {
  title: string
  text: string
  form: FormContent
}

interface NavLink {
  title: string
  url: string
  classes?: string
}
</script>

<script setup lang="ts">
import ShowroomInfos from '@/components/duo/ShowroomInfos.vue'
import FormNewsletter from '@/components/form/Newsletter.vue'
import { useChromeStore } from '@/stores/chrome'
import { push } from '@/utils/tracking'

const chrome = useChromeStore()

const onClick = (url: string) => {
  push({
    event: 'social_link_click',
    ctaLocation: 'footer',
    linkUrl: url,
  })
}

const onClickContact = (item: NavLink) => {
  if (item.classes) {
    push({
      event: 'contact_cta_click',
      ctaLocation: 'footer',
    })
  }
}
</script>

<style lang="scss" scoped>
.footer {
  padding-top: 6.4rem;
  border-top: 1px solid $c-grey-light;
}

.footer-inner {
  display: flex;
  justify-content: space-between;
}

.footer__menu,
.footer__some,
.footer__legal__list {
  @extend %list-nostyle;
}

.footer__menu__item__link {
  @extend %fw-semi;

  color: $c-black;
  font-size: 2rem;
  line-height: 3rem;
  opacity: 1;
  transition: 0.2s $ease-in;
  will-change: opacity;

  &:hover {
    opacity: 0.5;
  }
}

.footer__some,
.footer__showroom {
  @include mq($until: m) {
    display: none;
  }
}

.footer__some {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  margin-top: 4.8rem;
}

.footer__some__item__link {
  opacity: 1;
  transition: 0.2s $ease-in;
  will-change: opacity;

  &:hover {
    opacity: 0.5;
  }
}

.footer__showroom {
  justify-content: space-between;
  width: col(12);
}

.footer__newsletter {
  // prettier-ignore
  @include fluid(margin-top, (xxs: 6.4rem, xxl: 4.8rem));
}

.footer__legal {
  padding-top: 6.4rem;
  padding-bottom: $spacing * 0.5;
}

.footer__legal__list {
  display: flex;
  align-items: center;
  gap: 2rem;

  li:last-of-type {
    margin-left: auto;
  }
}

.footer__legal__list__item {
  color: $c-grey-medium;
  transition: 0.2s $ease-in;
  will-change: color;

  &:hover {
    color: $c-black;
  }
}

.footer__legal__epic {
  display: inline-flex;
  opacity: 0.4;

  .icon {
    width: 1.5rem;
    height: 1.5rem;
    transition: 0.2s $ease-in;
    will-change: fill;

    &:hover {
      fill: $c-epic;
    }
  }
}

.footer__action {
  width: 100%;
  max-width: 38rem;
}
</style>
