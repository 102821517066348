<!-- eslint-disable vue/no-v-html -->
<template>
  <dialog ref="rootElRef" class="modal">
    <button autofocus class="modal__close" @click="close">
      <SvgSprite class="icon" symbol="ui-close" size="40" aria-hidden="true" />
    </button>

    <PicturesSlider
      v-if="content.pictures"
      :content="content.pictures"
    ></PicturesSlider>

    <div class="modal__footer">
      <div class="modal__content">
        <h4 class="modal__title" v-html="content.title"></h4>
        <div
          v-if="content.brand"
          class="modal__brand body-text"
          v-html="content.brand"
        ></div>
      </div>

      <div class="modal__action">
        <Action
          v-if="content.pdfUrl"
          :content="{
            title: 'Fiche technique',
            icon: 'download',
            modifiers: ['reversed', 'white'],
            url: `${content.pdfUrl}`,
            target: '_blank',
          }"
        />
      </div>
    </div>
  </dialog>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'

import PicturesSlider from '@/components/slider/Pictures.vue'
import { useUiStore } from '@/stores/ui'

import type { Card } from '@/types'

// Refs
const rootElRef = ref<HTMLDialogElement>()

const uiStore = useUiStore()
const content = uiStore.modalContent as Card

const close = () => {
  uiStore.modalVisible = false
}

const addESC = (e: { keyCode: number }) => {
  if (e.keyCode === 27) {
    uiStore.modalVisible = false
  }
}

onMounted(() => {
  if (rootElRef.value) {
    rootElRef.value.showModal()
    document.addEventListener('keydown', addESC)
  }
})
</script>

<style lang="scss" scoped>
.modal {
  width: 100%;
  padding: $spacing * 2.5;

  @include mq($until: m) {
    height: 60%;
  }
}

::backdrop {
  background: rgba($c-black, 80%);
}

.modal__close {
  @extend %button-nostyle;
  @extend %focusable;

  position: absolute;
  top: 0.5rem;
  right: $spacing;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}

.modal__footer {
  @include mq($until: m) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.8rem;
  }
}

.modal__title {
  margin-block: 0;
  padding-right: 2.4rem;
}

.modal__brand {
  padding-right: 2.4rem;
  color: $c-grey-dark;
}

.modal__content {
  @include mq(m) {
    position: absolute;
    bottom: $spacing;
    left: $spacing;
  }
}

.modal__action {
  @include mq(m) {
    position: absolute;
    right: $spacing;
    bottom: $spacing;
  }
}
</style>
