/* eslint-disable @typescript-eslint/no-explicit-any */
import chalk from 'chalk'

import { emoji } from '@/config/app'

import type { ChalkInstance } from 'chalk'

const ssrColors: { [key: string]: ChalkInstance } = {
  TRACE: chalk.magenta,
  DEBUG: chalk.gray,
  LOG: chalk.gray,
  INFO: chalk.cyan,
  WARN: chalk.yellow,
  ERROR: chalk.red,
  SUCCESS: chalk.green,
  FAIL: chalk.red,
}

const colors: { [key: string]: string } = {
  TRACE: '#ee82ee',
  DEBUG: '#9aa0a6',
  LOG: '#9aa0a6',
  INFO: '#4d89ff',
  WARN: '#f0aa25',
  ERROR: '#ff8080',
  SUCCESS: '#0d9d59',
  FAIL: '#ff8080',
}

const enabled = import.meta.env.MODE !== 'production'
// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
const noop = () => {}

const init = (fn: (...data: any[]) => void, name: string) => {
  const spaces = 8 - name.length
  const color = import.meta.env.SSR
    ? ssrColors[name.toUpperCase()]
    : colors[name.toUpperCase()]
  const log: any[] = []
  let prefix: string

  switch (name) {
    case 'info':
      prefix = 'ℹ'
      break

    case 'warn':
      prefix = '➤'
      break

    case 'error':
    case 'fail':
      prefix = '✖︎'
      break

    case 'success':
      prefix = '✔'
      break

    default:
      prefix = '•'
      break
  }
  const label = `${prefix} ${name.toUpperCase()}${' '.repeat(spaces)}`

  if (import.meta.env.SSR) {
    log.push(`${emoji} ${(color as ChalkInstance)(label)} %s`)
  } else {
    if (['trace', 'warn', 'error', 'fail'].includes(name)) {
      log.push(`${emoji} %c${label} %c%s`)
    } else {
      log.push(`%c  %c${emoji} %c${label} %c%s`)
      log.push('font-size: 0.75em')
      log.push('font-size: inherit')
    }

    log.push(`color: ${color}`)
    log.push('color: inherit')
  }

  return enabled ? fn.bind(globalThis.console, ...log) : noop
}

const logger = {
  print: enabled ? globalThis.console.log.bind(globalThis.console, '%s') : noop,
  trace: init(globalThis.console.trace, 'trace'),
  log: init(globalThis.console.log, 'log'),
  debug: init(globalThis.console.log, 'debug'),
  info: init(globalThis.console.info, 'info'),
  warn: init(globalThis.console.warn, 'warn'),
  error: init(globalThis.console.error, 'error'),
  fail: init(globalThis.console.error, 'fail'),
  success: init(globalThis.console.info, 'success'),
}

export { logger }
