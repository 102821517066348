<template>
  <div>
    <RouterView
      :key="(route.meta.key as string) || route.fullPath"
      :content="content"
    />
  </div>
</template>

<script setup lang="ts">
import { useRoute } from 'vue-router'

const route = useRoute()

defineProps({
  content: {
    type: Object,
    required: true,
  },
})
</script>
