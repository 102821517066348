import axios from 'axios'
import { defineStore } from 'pinia'

import { getApiUrl } from '@/config/app'
import { langDefault } from '@/config/languages'
import { logger } from '@/utils/logger'

import type { HeadAttrs } from '@vueuse/head'
import type { AxiosError, AxiosResponse } from 'axios'
import type { Language } from '@/types'

interface NavLink {
  title: string
  url: string
  classes?: string
  children?: NavLink[]
}

interface Newsletter {
  title: string
  text: string
  form: Object
}

interface Showroom {
  title: string
  htmltext: string
}

export type Menus = {
  footer: NavLink[]
  legals: NavLink[]
  main: NavLink[]
  top: NavLink[]
}

interface Contact {
  addressUrl: string
  address: string
  phone: NavLink
}

interface ChromeState {
  i18n: Record<string, string>
  script: HeadAttrs[]
  language: Language
  newsletter: Newsletter
  showroomInfos: Showroom[]
  cta: NavLink
  contact: Contact
  menus: Menus
  socialNetworks: NavLink[]
}

interface ChromeResponse {
  script: HeadAttrs[]
  newsletter: Newsletter
  showroomInfos: Showroom[]
  cta: NavLink
  contact: Contact
  menus: Menus
  socialNetworks: NavLink[]
}

export type Chrome = ChromeState

const defaultState = (): ChromeState => ({
  i18n: {},
  script: [],
  language: langDefault,
  newsletter: {
    title: '',
    text: '',
    form: {},
  },
  showroomInfos: [],
  cta: {
    title: '',
    url: '',
  },
  contact: {} as Contact,
  menus: {} as Menus,
  socialNetworks: [],
})

export const useChromeStore = defineStore('chrome', {
  state: defaultState,
  actions: {
    async fetchChrome(language: Language = langDefault) {
      logger.info('[fetchChrome]', language)
      try {
        const response = (await axios.get(
          `${getApiUrl()}/chrome`
        )) as AxiosResponse<ChromeResponse>
        const data = response?.data

        this.language = language
        this.script = data.script
        this.newsletter = data.newsletter
        this.showroomInfos = data.showroomInfos
        this.cta = data.cta
        this.contact = data.contact
        this.menus = data.menus
        this.socialNetworks = data.socialNetworks
      } catch (error) {
        if ((error as AxiosError).isAxiosError) {
          logger.error(
            '[fetchChrome]',
            (error as AxiosError).code,
            (error as AxiosError).message
          )
        } else {
          logger.error('[fetchChrome]', (error as Error).message)
        }
      }
    },
  },
})
