<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="html-text-outer">
    <component :is="component"></component>
  </div>
</template>

<script setup lang="ts">
import { compile } from 'vue'

import type { PropType } from 'vue'

interface HTMLText {
  htmltext: string
}

const props = defineProps({
  content: {
    type: [Object, String] as PropType<HTMLText | String>,
    required: true,
  },
})

const component = compile(
  `<div class="html-text wysiwyg">${
    (props.content as HTMLText).htmltext || props.content
  }</div>`
)
</script>
