import { defineStore } from 'pinia'

import type { RouteRecordName } from 'vue-router'

// import { RouteLocationNormalized } from 'vue-router'

interface NavigationState {
  referrer: string | null
  popstate: boolean
  // from?: RouteLocationNormalized
  // to?: RouteLocationNormalized
  from?: RouteRecordName | null
  to?: RouteRecordName | null
  trigger?: string // 'listing' | 'megamenu'
}

const defaultState = (): NavigationState => ({
  referrer: null,
  popstate: false,
  from: null,
  to: null,
  trigger: undefined,
})

export const useNavigationStore = defineStore('navigation', {
  state: defaultState,
})
