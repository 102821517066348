/**
 * Add CSS modifiers to base class.
 */
export const useModifiers = (base: string, mods: string[] = []) => {
  if (!base) {
    return ''
  }

  return mods.length === 0 ? base : `${base}--${mods.join('--')}`
}
