import type { Chrome } from '@/stores/chrome'
import type { Language } from '@/types'

import axios from 'axios'
import { createI18n } from 'vue-i18n'

import { langAvailable, langDefault } from '@/config/languages'
import translationKeys from '@/i18n/locales/translationKeys.json'

const messages = langAvailable.reduce(
  (acc, lang) => {
    acc[lang] = {}

    return acc
  },
  {} as Record<Language, Record<string, string>>
)

messages[langDefault] = translationKeys // locales are fetched with the chrome

const i18n = createI18n({
  legacy: false,
  locale: langDefault,
  fallbackLocale: langDefault,
  globalInjection: true,
  messages,
})

export default i18n

export function setI18nLanguage(
  lang: Language,
  locales: Chrome['i18n'],
  isClient: boolean
) {
  // Set locale
  i18n.global.locale.value = lang
  axios.defaults.headers.common['Accept-Language'] = lang

  if (isClient) {
    // Not really needed because of `vue-meta`…
    // however document is only available client side
    document.documentElement.lang = lang
  }

  // Update messages with locales
  i18n.global.setLocaleMessage(lang, locales)

  return lang
}
