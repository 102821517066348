import axios from 'axios'
import Cookies from 'js-cookie'

import { getApiUrl } from '@/config/app'
import { langAvailable, langDefault } from '@/config/languages'
import { fetchResource, updateResource } from '@/core/resource'
import { setI18nLanguage } from '@/i18n'
import { useChromeStore } from '@/stores/chrome'
import { useNavigationStore } from '@/stores/navigation'
import { logger } from '@/utils/logger'
import { push } from '@/utils/tracking'

import type { AxiosRequestHeaders } from 'axios'
import type { Request, Response } from 'express'
import type { Pinia } from 'pinia'
import type { SharedContext } from 'vite-ssr/utils/types'
import type { Language, SSRContext } from '@/types'

export const createGuards = (
  { router, initialState, request, response, writeResponse }: SSRContext,
  pinia: Pinia
) => {
  let isFirstRendering = true

  // Manage env/feature branches
  axios.interceptors.request.use(config => {
    const isAppApiCall = config.url?.includes(getApiUrl())
    const isDev = import.meta.env.MODE === 'development'
    const isStaging = import.meta.env.MODE === 'staging'
    const isSSR = import.meta.env.SSR
    let backEnv = 'staging'

    // Do not sepcify epic_env_XXX headers if
    // calling something else than API
    // or is neither in dev or staging
    if (!isAppApiCall || (!isDev && !isStaging)) {
      return config
    }

    if (isSSR) {
      if (request?.headers.cookie) {
        const cookies = request.headers.cookie.split(/\s*;\s*/)
        const envBackStr = cookies.find((c: string) =>
          c.startsWith('epic_env_back')
        )

        if (envBackStr) {
          const [, envBackCookie] = envBackStr.split('=')

          backEnv = envBackCookie
        }
      }
    } else {
      backEnv = Cookies.get('epic_env_back') || backEnv
    }

    if (__FEATURE__ !== 'none') {
      backEnv = __FEATURE__
    }

    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders
    }

    /* eslint-disable */
    config.headers.epic_env_back = backEnv
    /* eslint-enable */

    return config
  })

  // Log
  router.beforeEach((to, from, next) => {
    logger.info('[guards] from', from.name, 'to', to.name)

    const navigation = useNavigationStore(pinia)

    navigation.from = from.name
    navigation.to = to.name
    next()
  })

  // Manage languages
  router.beforeEach(async (to, from, next) => {
    const chrome = useChromeStore(pinia)
    const { lang: langCurrent } = from.params as { lang: Language }
    const lang = (to.params.lang as Language) || langDefault

    if (
      to.meta.state === null &&
      from.name === undefined &&
      to.meta.chrome !== false
    ) {
      // First load on SSR, chrome is fetched and locales are set
      await chrome.fetchChrome(lang)
      setI18nLanguage(lang, chrome.i18n, false)
    }

    chrome.language = lang

    if (langAvailable.length > 1) {
      const langHasChanged = langCurrent !== undefined && langCurrent !== lang
      const langNext = langAvailable.includes(lang) ? lang : langDefault

      if (langHasChanged && to.meta.chrome !== false) {
        axios.defaults.headers.common['Accept-Language'] = langNext

        try {
          await chrome.fetchChrome(langNext)

          setI18nLanguage(langNext, chrome.i18n, !import.meta.env.SSR)
        } catch (error) {
          logger.error(error)
        }
      }
    }

    next()
  })

  // Fetch
  // Before each route navigation we request the data needed for showing the page.
  router.beforeEach(async (to, from, next) => {
    // Redirect from auth (failed) route can lead to "like-a-first-rendering"
    const redirectFromAuth = to.redirectedFrom?.meta.auth === true

    if (!import.meta.env.SSR && (isFirstRendering || redirectFromAuth)) {
      // This route has state already (from server) so it can be reused.
      // State is always empty in SPA development, but present in SSR development.
      let { resource } = initialState

      // If no SSR or redirect from auth (failed), no initial state, fetch the resource
      if (to.meta.ssr === false || to.meta.auth === true || redirectFromAuth) {
        ;[resource] = await fetchResource(to, request as Request)
      }

      // Hydrate resource store
      resource && updateResource(resource)
      isFirstRendering = false

      return next()
    }

    // Explanation:
    // The first rendering happens in the server. Therefore, when this code runs,
    // the server makes a request to itself (running the code below) in order to
    // get the current page props and use that response to render the HTML.
    // The browser shows this HTML and rehydrates the application, turning it into
    // a normal SPA. After that, subsequent route navigation runs this code below
    // from the browser and get the new page props, which is this time rendered
    // directly in the browser, as opposed to the first page rendering.

    try {
      const [resource, error, headers] = await fetchResource(
        to,
        request as Request
      )

      if (resource) {
        if (
          import.meta.env.MODE === 'production' ||
          import.meta.env.MODE === 'staging'
        ) {
          const mergedHeaders: SharedContext['ServerResponse']['OutgoingHttpHeaders'] =
            {
              ...(response?.getHeaders() || {}),
              'Content-Type': 'text/html',
            }

          if (headers['cache-control']) {
            mergedHeaders['Cache-Control'] = headers['cache-control']
          } else {
            mergedHeaders['Cache-Control'] = 'no-store'
          }
          ;(response as Response)?.set(mergedHeaders)
        }

        if (import.meta.env.SSR && resource.template === 'notfound') {
          writeResponse({
            status: 404,
            headers: {},
          })
        }

        // First SSR rendering, render resource (@see App.vue)
      } else if (error && import.meta.env.MODE === 'development') {
        // Catch fetch errors.
      }

      // This route meta state will be available as props in the page component.
      // This will also be added to the initialState.
      to.meta.state = { resource, error }
    } catch (error) {
      logger.error('[guards]', error)
    }

    return next()
  })

  // GTM - Analytics
  router.afterEach((to, from) => {
    // Only client side, skip first display
    // if (!import.meta.env.SSR) {
    if (!import.meta.env.SSR && from.name) {
      // GTM: page view
      const layer = {
        event: 'page_view',
        pageTitle:
          to.meta.state?.resource?.head?.title ||
          to.meta.state?.resource?.content?.title ||
          document.title,
        pageLocation: window.location.href,
        pagePath: to.fullPath,
      }

      push(layer)
    }
  })
}
